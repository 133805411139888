import { useTranslation } from "react-i18next";
import { useWizard } from "../../../components/Wizard/Wizard";
import WelcomeStep from "../WelcomeStep";

export default function WelcomeBiologicalStep() {
  const { t } = useTranslation();
  const { goToNextStep } = useWizard();

  return (
    <WelcomeStep
      confirmation={t("pages.booking.biological.steps.welcome.form.confirm")}
      instructionKey="pages.booking.biological.steps.welcome.instructions"
      onSubmit={goToNextStep}
      title={t("pages.booking.biological.steps.welcome.title")}
    />
  );
}
