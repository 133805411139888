import { startOfDay } from "date-fns";
import React, { useContext } from "react";
import ViewModeDay from "./viewModes/ViewModeDay";
import ViewModeMonth from "./viewModes/ViewModeMonth";

export enum VIEW_MODE {
  MONTH,
  DAY,
}

export interface CalendarEvent {
  start_datetime: Date;
  end_datetime: Date;
}

type CalendarContextProps = {
  date: Date;
  events: CalendarEvent[];
  selectedEvent?: CalendarEvent;
  minDate?: Date;
  maxDate?: Date;
  viewMode: VIEW_MODE;
  setDate: (date: Date) => void;
  onEventClick: (event: CalendarEvent) => void;
  onShowMoreClick?: (date: Date) => void;
};

const CalendarContext = React.createContext({} as CalendarContextProps);

export function useCalendar() {
  return useContext(CalendarContext);
}

export type DayFocusModalAPI = {
  show: () => void;
  hide: () => void;
  opened: boolean;
};

type CalendarProps = {
  date?: Date;
  viewMode?: VIEW_MODE;
  events?: CalendarEvent[];
  selectedEvent?: CalendarEvent;
  minDate?: Date;
  maxDate?: Date;
  onDateChange: (date: Date) => void;
  onEventClick: (event: CalendarEvent) => void;
  onShowMoreClick?: (date: Date) => void;
};

export default function Calendar({
  date = new Date(),
  viewMode = VIEW_MODE.MONTH,
  minDate,
  maxDate,
  events = [],
  selectedEvent,
  onEventClick,
  onDateChange,
  onShowMoreClick,
}: CalendarProps) {
  minDate = minDate && startOfDay(minDate);
  maxDate = maxDate && startOfDay(maxDate);

  return (
    <div className="Calendar">
      <CalendarContext.Provider
        value={{
          date,
          events,
          selectedEvent,
          minDate,
          maxDate,
          viewMode,
          setDate: onDateChange,
          onShowMoreClick,
          onEventClick,
        }}
      >
        {viewMode === VIEW_MODE.MONTH && <ViewModeMonth />}
        {viewMode === VIEW_MODE.DAY && <ViewModeDay />}
      </CalendarContext.Provider>
    </div>
  );
}
