import { QueryClient, QueryClientProvider } from "react-query";
import Layout from "./components/Layout/Layout";
import AppContextProvider from "./contexts/AppContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <Layout />
      </AppContextProvider>
    </QueryClientProvider>
  );
}
