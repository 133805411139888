import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import CheckBoxField from "../../components/Inputs/CheckBoxField";
import Container from "../../components/Layout/Container";
import PageTitle from "../../components/Typography/PageTitle";

type WelcomeStepProps = {
  confirmation: string;
  instructionKey: string;
  onSubmit: () => void;
  title: string;
};

export default function WelcomeStep({
  confirmation,
  instructionKey,
  onSubmit,
  title,
}: WelcomeStepProps) {
  const { t } = useTranslation();
  const { register, handleSubmit, formState } = useForm({
    defaultValues: { toggle: false },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-lns-gray-background">
        <Container padded className="py-8 lg:py-12">
          <PageTitle>{title}</PageTitle>
          <div>
            <Trans
              i18nKey={instructionKey}
              components={{
                ul: <ul className="list-disc list-inside space-y-6" />,
              }}
            />
          </div>
        </Container>
      </div>

      <Container padded>
        <div className="my-8">
          <CheckBoxField
            {...register("toggle", { required: true })}
            label={<span className="text-lns-primary">{confirmation}</span>}
          />
        </div>

        <div className="flex gap-x-6">
          <Button variant="outline-primary" as={Link} to="/">
            {t("components.buttons.cancel")}
          </Button>

          <Button variant="primary" type="submit" disabled={!formState.isValid}>
            {t("components.buttons.next")}
          </Button>
        </div>
      </Container>
    </form>
  );
}
