import axios, { AxiosError } from "axios";
import { ErrorResponse } from "../models/error";

const ERROR_MESSAGE_KEYS = {
  // 2xx
  "[201]": "errors.appointment_already_exists",
  "[202]": "errors.appointment_waiting_payment",

  // 3xx
  "[301]": "errors.uncomplete_enterprise_info",
  "[302]": "errors.misa_approval_not_supported",
  "[303]": "errors.invalid_vat_number",
  "[305]": "errors.invalid_business_date",
  "[309]": "errors.total_prescriptions_exceeded",
  "[310]": "errors.total_misa_reports_exceeded",

  // 4xx
  "[401]": "errors.total_daily_medicine_exceeded",
  "[409]": "errors.appointment_date_too_close",
  "[410]": "errors.last_reaction_date_too_close",
};

const UNEXPECTED_ERROR_KEY = "errors.unexpected";

export const getErrorMessageKey = (error: Error | AxiosError) => {
  if (!axios.isAxiosError(error)) return UNEXPECTED_ERROR_KEY;

  const response: ErrorResponse = error.response?.data;

  const code = extractCodeFromDescription(response?.description);

  if (!code) return UNEXPECTED_ERROR_KEY;

  const message = ERROR_MESSAGE_KEYS[code];

  if (!message) return UNEXPECTED_ERROR_KEY;

  return message;
};

const extractCodeFromDescription = (description?: string) => {
  if (!description) return null;

  const matches = description.match(/\[[0-9]{3}\]/);

  if (!matches || matches.length === 0) return null;

  return matches[0] as keyof typeof ERROR_MESSAGE_KEYS;
};
