import isEmpty from "lodash/isEmpty";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AppointmentsTable from "../components/Appointment/AppointmentsTable";
import ModalTestSelection from "../components/Appointment/ModalTestSelection";
import Button from "../components/Button/Button";
import Empty from "../components/Empty/Empty";
import { ReactComponent as LogoDashboard } from "../components/icons/logo_dashboard.svg";
import Container from "../components/Layout/Container";
import Loading from "../components/Loading/Loading";
import PageTitle from "../components/Typography/PageTitle";
import { useApp } from "../contexts/AppContext";
import { useAppointments } from "../hooks/useAppointments";
import { isAppointmentTypeEnabled } from "../utils/appointment";

export default function AnalysesPage() {
  const { i18n, t } = useTranslation();
  const { isPatient, isMisa, showError, hideError } = useApp();
  const { isLoading, data, error } = useAppointments(isMisa);

  const [showTestSelection, setShowTestSelection] = useState(false);

  function handleCloseTestSelectionModal() {
    setShowTestSelection(false);
  }

  useEffect(() => {
    error ? showError(error) : hideError();
  }, [error, showError, hideError]);

  const appointments = useMemo(() => {
    if (!data) return [];
    return data.filter((item) =>
      isAppointmentTypeEnabled(item.appointment_type)
    );
  }, [data]);

  const hasAppointments = !isEmpty(appointments);

  if (isLoading) {
    return <Loading message={t("pages.your_analysis.loading")} />;
  }

  return (
    <Container padded>
      <div className="flex flex-col lg:flex-row">
        <div className="flex-1">
          <PageTitle>
            {isPatient
              ? t("pages.your_analysis.title")
              : t("pages.your_analysis.misa_title")}
          </PageTitle>
        </div>

        <div className="flex flex-col gap-x-6 gap-y-3 lg:flex-row">
          {isPatient && (
            <div>
              <Button
                variant="primary"
                onClick={() => setShowTestSelection(true)}
                className="w-full"
              >
                {t("components.buttons.schedule_test")}
              </Button>
            </div>
          )}
          <div className="flex-auto lg:order-first">
            <Button
              variant="outline-primary"
              as="a"
              href={
                i18n.language === "fr"
                  ? "https://lns.lu/contact/"
                  : "https://lns.lu/en/contact-en/"
              }
              className="w-full lg:order-first"
              target="_blank"
              rel="noreferrer"
            >
              {t("components.buttons.contact_us")}
            </Button>
          </div>
        </div>
      </div>

      {!hasAppointments && (
        <Empty
          image={<LogoDashboard className="inline-block mb-6" />}
          title={t("pages.your_analysis.no_appointments.title")}
        >
          <p className="mb-6">
            {t("pages.your_analysis.no_appointments.subtitles.0")}
          </p>
          <p>{t("pages.your_analysis.no_appointments.subtitles.1")}</p>
        </Empty>
      )}

      {hasAppointments && <AppointmentsTable appointments={appointments} />}

      <ModalTestSelection
        open={showTestSelection}
        onClose={handleCloseTestSelectionModal}
      />
    </Container>
  );
}
