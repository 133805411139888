import clsx from "clsx";
import { ReactNode } from "react";
import { ReactComponent as Spinner } from "../icons/spinner.svg";

const variants = {
  primary: "bg-lns-primary border-lns-primary text-lns-white",
  success: "bg-lns-success border-lns-success text-lns-white",
  "outline-primary": "bg-lns-white border-lns-primary text-lns-primary",
  error: "bg-lns-error border-lns-error text-lns-white",
};

const disabledVariants = {
  primary:
    "bg-lns-primary-disabled border-lns-primary-disabled text-lns-primary",
  success: "bg-lns-success-disabled border-lns-success-disabled",
  "outline-primary":
    "bg-lns-primary-disabled border-lns-primary-disabled text-lns-primary",
  error: "bg-lns-gray-disabled border-lns-gray-disabled",
};

type ButtonProps = {
  children: ReactNode;
  disabled?: boolean;
  variant?: "primary" | "error" | "success" | "outline-primary";
  icon?: ReactNode;
  className?: string;
  loading?: boolean;
  as?: React.ElementType;
  [otherProps: string]: any;
};

export default function Button({
  children,
  as,
  icon,
  variant = "primary",
  disabled,
  loading,
  className,
  ...otherProps
}: ButtonProps) {
  disabled = loading || disabled;

  const Component = as ? as : "button";
  const defaultProps = Component === "button" ? { type: "button" } : {};

  return (
    <Component
      {...defaultProps}
      {...otherProps}
      onClick={loading || disabled ? undefined : otherProps.onClick}
      disabled={disabled}
      className={clsx(
        "flex flex-row gap-x-2 h-10 justify-center items-center border p-2 px-4 max-h-10 mb-0 font-medium cursor-pointer rounded",
        disabled ? disabledVariants[variant] : variants[variant],
        className
      )}
    >
      {loading && <Spinner className="w-3 h-3 animate-spin" />}
      {!loading && (
        <>
          {icon && <span>{icon}</span>}
          <span className="text-center truncate whitespace-nowrap">
            {children}
          </span>
        </>
      )}
    </Component>
  );
}
