import { Profile } from "../models/profile";

export const patientProfile: Profile = {
  firstName: "Cameron",
  lastName: "rogers",
  lang: "en",
  email: "hello@example.com",
  attributes: {
    person_code: ["123123123123"],
    birth_date: [new Date()],
    phone: ["+123456789"],
    mobile: ["+234567891"],
    street: ["5, Rue de address"],
    zip: ["5512"],
    city: ["Luxembourg"],
    country_id: [352],
  },
};

export const misaProfile: Profile = {
  firstName: "John",
  lastName: "Misa",
  lang: "en",
  email: "john@doe.com",
  attributes: {
    person_code: ["321321321321"],
    birth_date: [new Date()],
    phone: ["+987654321"],
    mobile: ["+876543219"],
    street: ["5, Rue de address"],
    zip: ["5512"],
    city: ["Luxembourg"],
    country_id: [352],
  },
};
