type EmptyProps = {
  children?: React.ReactNode;
  image?: React.ReactNode;
  subtitle?: string;
  title: string;
};

export default function Empty({
  children,
  image,
  subtitle,
  title,
}: EmptyProps) {
  return (
    <div className="mt-12 text-center h-96">
      {image}
      <h2 className="mb-6 text-2xl font-bold">{title}</h2>
      <div className="max-w-2xl mx-auto mb-6">
        {subtitle}
        {children}
      </div>
    </div>
  );
}
