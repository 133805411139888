import MockAdapter from "axios-mock-adapter";
import { DEBUG, DEBUG_FETCH_DELAY } from "../config";
import endpoints from "../endpoints";
import { AppointmentState, AppointmentType } from "../models/appointment";
import {
  appointmentDetails,
  misaAppointments,
  patientAppointments,
} from "../__mocks__/appointments";
import timeslots from "../__mocks__/timeslots";
import ApiService from "./ApiService";
import {
  GetAppointmentDetailsResponse,
  GetAppointmentsResponse,
} from "./AppointmentService";
import { Roles } from "./AuthService";
import { GetTimeslotResponse } from "./TimeslotService";

if (DEBUG) {
  const role = sessionStorage.getItem("demo-role");
  const isMisa = role === Roles.MISA;

  const mockAxios = new MockAdapter(ApiService, {
    delayResponse: DEBUG_FETCH_DELAY,
  });

  let appointmentDetailsSession = appointmentDetails;
  let misaAppointmentsSession = misaAppointments;

  mockAxios.onPut(endpoints.updateProfileLanguage()).reply(200, null);

  mockAxios
    .onGet(route(endpoints.getTimeslots(":appointment_type")))
    .reply(200, {
      data: timeslots,
      size: timeslots.length,
    } as GetTimeslotResponse);

  mockAxios.onGet(endpoints.getAppointments(isMisa)).reply(() => {
    const results = isMisa ? misaAppointmentsSession : patientAppointments;

    return [
      200,
      { data: results, size: results.length } as GetAppointmentsResponse,
    ];
  });

  mockAxios
    .onGet(
      route(
        endpoints.getAppointmentDetails(
          ":appointment_type",
          ":appointmentId",
          true
        )
      )
    )
    .reply((config) => {
      const appointment_type = config.url?.split("/")[2];
      const appointmentId = config.url?.split("/")[4];
      const appointment = appointmentDetailsSession.find(
        (a) =>
          a.id === Number(appointmentId) &&
          a.appointment_type === appointment_type
      );
      return [
        200,
        { data: [appointment], size: 1 } as GetAppointmentDetailsResponse,
      ];
    });

  mockAxios
    .onGet(
      route(
        endpoints.getAppointmentDetails(
          ":appointment_type",
          ":appointmentId",
          false
        )
      )
    )
    .reply((config) => {
      const appointment_type = config.url?.split("/")[2];
      const appointmentId = config.url?.split("/")[4];
      const appointment = appointmentDetailsSession.find(
        (a) =>
          a.id === Number(appointmentId) &&
          a.appointment_type === appointment_type
      );
      return [
        200,
        { data: [appointment], size: 1 } as GetAppointmentDetailsResponse,
      ];
    });

  mockAxios
    .onPut(
      route(endpoints.approveMisaEnvironmentalAppointment(":appointmentId"))
    )
    .reply((config) => {
      const { approval, feedback } = JSON.parse(config.data);
      const changes = {
        id: Number(config.url?.split("/")[4]),
        appointment_type: "environmental" as AppointmentType,
        misa_approval: (approval === "yes" ? "yes" : "no") as "yes" | "no",
        misa_approval_date: new Date(),
        misa_approval_feedback: feedback,
        state: "planned" as AppointmentState,
      };
      misaAppointmentsSession = updateAppointment(
        misaAppointmentsSession,
        changes
      );
      appointmentDetailsSession = updateAppointment(
        appointmentDetailsSession,
        changes
      );
      return [200];
    });

  mockAxios
    .onPut(
      route(endpoints.validateMisaEnvironmentalAppointment(":appointmentId"))
    )
    .reply((config) => {
      const changes = {
        id: Number(config.url?.split("/")[4]),
        appointment_type: "environmental" as AppointmentType,
        state: "due" as AppointmentState,
      };
      misaAppointmentsSession = updateAppointment(
        misaAppointmentsSession,
        changes
      );
      appointmentDetailsSession = updateAppointment(
        appointmentDetailsSession,
        changes
      );
      return [200];
    });

  mockAxios
    .onPost(route(endpoints.createAppointment(":appointment_type")))
    .reply(200, true);

  mockAxios
    .onDelete(
      route(endpoints.deleteAppointment(":appointmentId", ":appointment_type"))
    )
    .reply(200, true);
}

function route(path = "") {
  return typeof path === "string"
    ? new RegExp(path.replace(/:\w+/g, "[^/]+"))
    : path;
}

function updateAppointment<
  T extends { id: number; appointment_type: AppointmentType },
>(appointments: T[] = [], changes: Partial<T>) {
  return appointments.map((appointment) =>
    appointment.id === changes.id &&
    appointment.appointment_type === changes.appointment_type
      ? { ...appointment, ...changes }
      : appointment
  );
}
