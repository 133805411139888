import { ReactNode } from "react";
import Modal from "../Modal/Modal";

type AlertModalProps = {
  actions?: ReactNode;
  message: string;
  onClose?: () => void;
  open: boolean;
};

export default function AlertModal({
  actions,
  message,
  onClose,
  open,
}: AlertModalProps) {
  return (
    <Modal onCloseButtonClick={onClose} open={open} size="lg:max-w-md">
      <p className="text-center">{message}</p>
      <div className="flex flex-col mt-6 gap-y-3">{actions}</div>
    </Modal>
  );
}
