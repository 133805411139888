import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Bell } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useNotifications } from "../../hooks/useNotifications";

export default function NotificationDropdown() {
  const { notifications } = useNotifications();
  const { t } = useTranslation();
  const hasNotifications = notifications && notifications.length > 0;

  return (
    <Menu as="div" className="relative px-2.5">
      {/* TODO - Create your own Menu component that extends Headless/Menu */}
      <div>
        <Menu.Button className="relative flex flex-row items-center justify-center w-full gap-1 focus:outline-none">
          <Bell />
          {hasNotifications && (
            <span className="absolute top-0 right-0 inline-block w-1 h-1 transform translate-x-1/2 -translate-y-1/2 rounded-full bg-lns-badge-cancelled"></span>
          )}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute mt-2 rounded-md shadow-lg -left-48 w-96 bg-lns-white focus:outline-none">
          <div className="px-3 py-1 divide-y divide-lns-gray-border">
            {!hasNotifications && (
              <div className="py-3">
                {t("You don't have any notifications")}
              </div>
            )}
            {hasNotifications &&
              notifications.map((notification) => (
                <Menu.Item key={notification.appointmentId}>
                  {({ active }) => (
                    <Link
                      className={`${
                        active ? "bg-lns-gray-background" : null
                      } block text-left text-sm text-lns-black rounded-md w-full py-3 px-1`}
                      to={`/analyses/${notification.appointment_type}/${notification.appointmentId}`}
                    >
                      <div>
                        <span
                          className={`inline-block w-2.5 h-2.5 rounded-full bg-lns-badge-cancelled mr-2`}
                        ></span>
                        <strong>New request</strong> is waiting for approval
                      </div>
                      <div className="mt-2">
                        <span className="text-lns-gray-text">Analyze ID: </span>
                        <span>{notification.appointmentId}</span>
                        <span className="ml-4 text-lns-gray-text">Name: </span>
                        <span>{`${notification.name} ${notification.name}`}</span>
                      </div>
                    </Link>
                  )}
                </Menu.Item>
              ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
