import { HouseDoorFill } from "react-bootstrap-icons";
import { Link, useLocation } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import breadcrumbsRoutes from "../../config.breadcrumbs";

export default function Breadcrumb() {
  const breadcrumbs = useBreadcrumbs(breadcrumbsRoutes);
  const location = useLocation();

  return (
    <div className="flex flex-row items-center w-full py-4 overflow-x-auto scroll-snap-align-end flex-nowrap gap-x-1 lg:gap-x-2 text-lns-gray-text">
      {breadcrumbs.map(({ match, breadcrumb }, idx) => {
        const isLast = idx === breadcrumbs.length - 1;
        const isFirst = idx === 0;

        return (
          <div key={match.url} className="flex flex-row items-center gap-x-2">
            <Link
              to={isLast ? location : match.url}
              className="flex flex-row whitespace-nowrap"
            >
              {isFirst && (
                <span className="mr-1">
                  <HouseDoorFill size={20} />
                </span>
              )}
              {breadcrumb}
            </Link>

            {!isLast && (
              <span className="pt-1 text-xs whitespace-nowrap">►</span>
            )}
          </div>
        );
      })}
    </div>
  );
}
