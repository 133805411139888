import clsx from "clsx";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AlertModal from "../../components/AlertModal/AlertModal";
import AppointmentDetailsTable from "../../components/Appointment/AppointmentDetailsTable";
import Button from "../../components/Button/Button";
import DocumentsTable from "../../components/Documents/DocumentsTable";
import InvoiceList from "../../components/Documents/InvoiceList";
import ReportList from "../../components/Documents/ReportList";
import Container from "../../components/Layout/Container";
import DisclosurePanel from "../../components/Panel/DisclosurePanel";
import Timeline from "../../components/Timeline/Timeline";
import SubTitle from "../../components/Typography/SubTitle";
import { useApp } from "../../contexts/AppContext";
import { useAppointmentDeletion } from "../../hooks/useAppointments";
import { AppointmentDetails } from "../../models/appointment";
import { getDocumentsByType } from "../../utils/appointment";
import MedicalAnalysisPanel from "./MedicalAnalysisPanel";

type GeneralInfoTabProps = {
  appointment: AppointmentDetails;
};

export default function GeneralInfoTab({ appointment }: GeneralInfoTabProps) {
  const { isMisa, isPatient } = useApp();
  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { deleteAppointment, isDeleting } = useAppointmentDeletion();

  function handleHideDeleteConfirmation() {
    setShowConfirmation(false);
  }

  function handleShowDeleteConfirmation() {
    setShowConfirmation(true);
  }

  function handleDeleteAppointment(
    appointmentId: number,
    appointment_type: string
  ) {
    deleteAppointment(
      { appointmentId, appointment_type },
      {
        onError: () => {
          // An error happened!
        },
        onSuccess: () => {
          setShowConfirmation(false);
        },
      }
    );
  }

  /*
  const prescriptions = useMemo(
    () => getDocumentsByType(appointment, "Prescription"),
    [appointment]
  );
  */

  const technicianReports = useMemo(
    () => getDocumentsByType(appointment, "Technician report"),
    [appointment]
  );

  const glimsReports = useMemo(
    () => getDocumentsByType(appointment, "GLIMS report"),
    [appointment]
  );

  const misaReports = useMemo(
    () => getDocumentsByType(appointment, "MISA report"),
    [appointment]
  );

  return (
    <>
      <div className={clsx(isMisa && "bg-lns-gray-background mb-10")}>
        <Container className="divide-y divide-lns-gray-border gap-y-6" padded>
          <div
            className={clsx(
              "px-0.5 py-10 lg:px-6",
              appointment.appointment_type === "environmental" && "pt-5"
            )}
          >
            {appointment.appointment_type === "environmental" && (
              <Timeline appointment={appointment} isMisa={isMisa} />
            )}

            <SubTitle>
              {t("pages.appointment_details.general_information")}
            </SubTitle>
            <AppointmentDetailsTable appointments={[appointment]} />
          </div>
          {isPatient && (
            <>
              <div className="px-0.5 py-10 lg:px-6 space-y-6">
                <SubTitle>
                  {t("pages.appointment_details.diagnostic_reports")}
                </SubTitle>
                {appointment.appointment_type === "biological" ? (
                  <p>
                    {t("pages.appointment_details.mail_diagnostic_reports")}
                  </p>
                ) : (
                  <ReportList appointment={appointment} />
                )}
              </div>

              {appointment.appointment_type === "toxicology" && (
                <div className="px-0.5 py-10 lg:px-6 space-y-6">
                  <SubTitle>{t("pages.appointment_details.invoices")}</SubTitle>
                  <InvoiceList invoices={appointment.invoices} />
                </div>
              )}
            </>
          )}
          {appointment.appointment_type === "allergology" && (
            <div className="px-0.5 py-10 lg:px-6">
              <div className="flex flex-col lg:items-center lg:flex-row gap-y-3">
                <SubTitle className="flex-auto">
                  {t("pages.appointment_details.deleteAppointment")}
                </SubTitle>
                <Button
                  className="w-full lg:flex-none lg:w-min"
                  disabled={appointment.state_full === "canceled" || isDeleting}
                  onClick={handleShowDeleteConfirmation}
                  variant="error"
                >
                  {appointment.state_full === "canceled"
                    ? t("models.appointment.state.canceled")
                    : t("components.buttons.cancel")}
                </Button>
                <AlertModal
                  actions={
                    <>
                      <Button
                        variant="error"
                        loading={isDeleting}
                        onClick={() =>
                          handleDeleteAppointment(
                            appointment.id,
                            appointment.appointment_type
                          )
                        }
                        className="w-full"
                      >
                        {t("components.buttons.cancel_appointment")}
                      </Button>
                      <Button
                        variant="outline-primary"
                        onClick={handleHideDeleteConfirmation}
                        className="w-full"
                      >
                        {t("components.buttons.back")}
                      </Button>
                    </>
                  }
                  message={t(
                    "pages.appointment_details.deleteAppointmentConfirmationMessage"
                  )}
                  onClose={handleHideDeleteConfirmation}
                  open={showConfirmation}
                />
              </div>
            </div>
          )}
        </Container>
      </div>

      {isMisa && (
        <Container padded>
          <div className="space-y-4">
            {/* appointment.partner_type === "patient" && (
              <PrescriptionPanel
                appointment={appointment}
                prescriptions={prescriptions}
              />
            )*/}

            <DisclosurePanel
              title={t("pages.appointment_details.sampling_report")}
            >
              <p className="mb-6">
                {t("pages.appointment_details.sampling_report_description")}
              </p>
              <DocumentsTable
                documents={technicianReports}
                appointment={appointment}
              />
            </DisclosurePanel>

            <MedicalAnalysisPanel
              appointment={appointment}
              glimsReports={glimsReports}
              misaReports={misaReports}
            />
          </div>
        </Container>
      )}
    </>
  );
}
