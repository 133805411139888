import { useTranslation } from "react-i18next";
import { formatISO } from "../../utils/generic";
import { CalendarEvent } from "./Calendar";
import Event from "./Event";

type EventListProps = {
  events: CalendarEvent[];
  selectedEvent?: CalendarEvent;
  showMoreButton?: number;
  onEventClick: (event: CalendarEvent) => void;
  onShowMoreClick?: () => void;
};

function isSameEvent(event1?: CalendarEvent, event2?: CalendarEvent) {
  return event1 === event2;
}

export default function EventList({
  events,
  selectedEvent,
  showMoreButton = Infinity,
  onShowMoreClick,
  onEventClick,
}: EventListProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full gap-y-2">
      <ul className="space-y-1">
        {events.slice(0, showMoreButton).map((event, idx) => (
          <li key={idx}>
            <Event
              onClick={() => onEventClick(event)}
              selected={isSameEvent(selectedEvent, event)}
            >
              {formatISO(event.start_datetime, "H:mm")}
            </Event>
          </li>
        ))}
      </ul>

      {events.length > showMoreButton && (
        <div className="flex flex-row justify-end">
          <button
            type="button"
            onClick={() => onShowMoreClick?.()}
            className="underline text-lns-primary"
          >
            {t("components.buttons.see_more")}
          </button>
        </div>
      )}
    </div>
  );
}
