import React, { ReactNode, useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSetState } from "react-use";
import Alert from "../components/Alert/Alert";
import Loading from "../components/Loading/Loading";
import { useInitializer } from "../hooks/useInitializer";
import { Profile } from "../models/profile";
import { hasRole, Roles } from "../services/AuthService";
import { getErrorMessageKey } from "../utils/errorUtils";

type AppContextProps = {
  profile?: Profile;
  alert?: { type: "error" | "info"; message: string };
  showError: (exception: Error) => void;
  hideError: () => void;
  isMisa?: boolean;
  isPatient?: boolean;
};

export const AppContext = React.createContext({} as AppContextProps);

export function useApp() {
  return useContext(AppContext);
}

type AppContextProviderProps = {
  children: ReactNode;
};

export default function AppContextProvider({
  children,
}: AppContextProviderProps) {
  const [appState, setAppState] = useSetState();
  const { isLoading, data: profile, error } = useInitializer();
  const { i18n, t } = useTranslation();

  const showError = useCallback(
    (exception: Error) => {
      const errorMessageKey = getErrorMessageKey(exception);
      setAppState({
        alert: { type: "error", message: t(errorMessageKey) },
      });
    },
    [setAppState, t]
  );

  const hideError = useCallback(() => {
    setAppState({ alert: undefined });
  }, [setAppState]);

  useEffect(() => {
    if (profile) {
      setAppState({ profile: profile });
      i18n.changeLanguage(profile.lang);
    }
  }, [profile, setAppState, i18n]);

  useEffect(() => {
    setAppState({
      isMisa: hasRole([Roles.MISA]),
      isPatient: hasRole([Roles.PATIENT]),
    });
  }, [setAppState]);

  if (error) {
    return <Alert message={error.message} />;
  }

  return (
    <AppContext.Provider value={{ ...appState, showError, hideError }}>
      {isLoading ? <Loading message="Initialization..." /> : children}
    </AppContext.Provider>
  );
}
