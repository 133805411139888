import { ReactNode } from "react";

type CardProps = {
  children: ReactNode;
};

export default function Card({ children }: CardProps) {
  return (
    <div className="flex flex-col items-center flex-auto p-4 rounded shadow-lg bg-lns-white gap-y-3">
      {children}
    </div>
  );
}

Card.Title = function ({ children }: CardProps) {
  return <h3 className="font-bold text-center">{children}</h3>;
};

Card.Body = function ({ children }: CardProps) {
  return <h3 className="flex-1 mb-3">{children}</h3>;
};

Card.Action = function ({ children }: CardProps) {
  return <div className="mb-2">{children}</div>;
};
