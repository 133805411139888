import clsx from "clsx";
import { isAfter, isBefore } from "date-fns";
import isEmpty from "lodash/isEmpty";
import { Fragment, useMemo } from "react";
import { Calendar, X } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { AppointmentDetails } from "../../models/appointment";
import { formatISO } from "../../utils/generic";

// TODO refactor component to make it more generic + split into multiple/smaller components (TimelineItem, TimelineDot, TimelineConnector, ...)
// Avoid complex logic like ghostType/dotType
export interface Dot {
  stepN?: number;
  label?: string;
  status: "cancelled" | "due" | "planned";
  cancelDate?: Date;
  date?: Date;
}

type TimelineProps = {
  appointment?: AppointmentDetails;
  isMisa?: boolean;
};

type TimelineConnectorProps = {
  due?: boolean;
};

type TimelineItemProps = {
  cancelled?: boolean;
  children?: React.ReactNode;
  dot: Dot;
  dotType: boolean;
  ghostType: boolean;
};

function prepareCancelDate(
  cancelDate?: Date,
  datePrev?: Date,
  dateAfter?: Date
) {
  if (!cancelDate) {
    return undefined;
  }

  const isCancelledPrev =
    !datePrev || isBefore(new Date(cancelDate), new Date(datePrev));
  const isCancelledAfter =
    dateAfter && isAfter(new Date(cancelDate), new Date(dateAfter));

  if (isCancelledPrev || isCancelledAfter) {
    return undefined;
  }

  return cancelDate;
}

function prepareTimelineDots(
  isMisa: boolean,
  appointment?: AppointmentDetails
) {
  if (!appointment) {
    return [];
  }

  const {
    partner_type,
    cancel_date,
    create_date,
    misa_approval_date,
    report_generation_date,
    start_datetime,
  } = appointment;

  const isEnterprise = partner_type === "enterprise";

  // TODO - dots should most probably contain cancelled dot too
  let filledDots: Dot[] = [];
  let stepN = 1;
  filledDots.push({
    label: "pages.appointment_details.timeline.appointment_request",
    date: create_date,
    status: "due",
    stepN: stepN++,
  });
  if (!isEnterprise) {
    filledDots.push({
      label: "pages.appointment_details.timeline.approval",
      date: misa_approval_date,
      status: misa_approval_date ? "due" : "planned",
      cancelDate: prepareCancelDate(cancel_date, create_date),
      stepN: stepN++,
    });
  }
  filledDots.push({
    label: "pages.appointment_details.timeline.appointment_date",
    date: start_datetime,
    status: report_generation_date ? "due" : "planned",
    cancelDate: prepareCancelDate(
      cancel_date,
      isEnterprise ? create_date : misa_approval_date,
      start_datetime
    ),
    stepN: stepN++,
  });
  filledDots.push({
    label: "pages.appointment_details.timeline.generated_report",
    date: report_generation_date,
    status: report_generation_date ? "due" : "planned",
    cancelDate: prepareCancelDate(
      cancel_date,
      start_datetime,
      report_generation_date
    ),
    stepN: stepN++,
  });
  /*filledDots.push({
    label: "pages.appointment_details.timeline.report_date",
    date: misa_report_date,
    status: misa_report_date ? "due" : "planned",
    cancelDate: prepareCancelDate(
      cancel_date,
      report_generation_date,
      misa_report_date
    ),
    stepN: stepN++,
  });*/

  return filledDots;
}

function TimelineItem({
  cancelled,
  children,
  dot,
  dotType,
  ghostType,
}: TimelineItemProps) {
  const { t } = useTranslation();

  const due = dot.status === "due";
  const planned = dot.status === "planned";

  return (
    <Fragment>
      <div
        className={clsx(
          "stepper-item relative flex flex-row items-center gap-3",
          dotType && "lg:my-1.5"
        )}
      >
        <div
          className={clsx(
            "dot lg:px-3 flex flex-col",
            ghostType && "hidden lg:invisible"
          )}
        >
          <div
            className={clsx(
              "inline-flex items-center border justify-center text-center rounded-full w-8 h-8",
              due && "bg-lns-primary border-lns-primary text-lns-white ",
              planned && "bg-lns-white border-lns-gray-border text-lns-primary",
              cancelled &&
                "bg-lns-badge-cancelled border-lns-badge-cancelled text-lns-white"
            )}
          >
            {cancelled ? <X size={30} /> : dot.stepN}
          </div>
        </div>

        {dotType && (
          <div
            className={clsx(
              "bg-lns-white lg:absolute flex flex-row justify-between lg:flex lg:flex-col gap-1 px-4 py-3 text-sm lg:transform lg:-translate-x-1/2 rounded shadow-md lg:shadow-lg lg:left-1/2",
              cancelled
                ? "lg:mb-2 lg:bottom-full"
                : "lg:mt-2 flex-1 lg:top-full"
            )}
          >
            {dot.label && (
              <div className="truncate whitespace-nowrap text-lns-gray-text">
                {t(dot.label)}

                {cancelled &&
                  dot.date &&
                  ` ${formatISO(dot?.date, "dd / MM / yyyy")}`}
              </div>
            )}

            {!cancelled && (
              <div className="flex flex-row items-center gap-2 w-28 text-lns-gray-text whitespace-nowrap">
                <Calendar />
                {dot.date
                  ? formatISO(dot.date, "dd / MM / yyyy")
                  : " - / - / -"}
              </div>
            )}
          </div>
        )}
      </div>
      {children}
    </Fragment>
  );
}

function TimelineConnector({ due }: TimelineConnectorProps) {
  return (
    <div
      className={clsx(
        "divider lg:border-b flex-auto h-2 lg:h-auto ml-4 lg:ml-0 border-l lg:flex",
        due ? "border-lns-primary" : "border-lns-gray-border"
      )}
    ></div>
  );
}

export default function Timeline({
  appointment,
  isMisa = false,
}: TimelineProps) {
  let filledDots = useMemo(
    () => prepareTimelineDots(isMisa, appointment),
    [appointment, isMisa]
  );

  if (isEmpty(filledDots)) {
    return null;
  }

  return (
    <div className="mb-10 timeline lg:mt-14 lg:mb-0">
      <div className="w-full lg:h-40 stepper">
        <div className="flex flex-col justify-between lg:items-center lg:mt-10 lg:flex-row">
          {filledDots.map((dot, idx) => {
            const isLast = idx === filledDots.length - 1;
            const nextStatus = !isLast && filledDots[idx + 1].status;
            const prevStatus = idx !== 0 && filledDots[idx - 1].status;

            return (
              <Fragment key={idx}>
                {idx !== 0 && (
                  <TimelineItem
                    cancelled={!!dot.cancelDate}
                    dot={{
                      date: dot.cancelDate,
                      label: "pages.appointment_details.timeline.cancelled",
                      status: "cancelled",
                    }}
                    dotType={!!dot.cancelDate}
                    ghostType={!dot.cancelDate}
                  >
                    <TimelineConnector due={prevStatus === "due"} />
                  </TimelineItem>
                )}
                <TimelineItem dot={dot} dotType={true} ghostType={false}>
                  {!isLast && (
                    <TimelineConnector
                      due={dot.status === "due" || nextStatus === "due"}
                    />
                  )}
                </TimelineItem>
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
}
