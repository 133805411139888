import { useMutation, useQuery, useQueryClient } from "react-query";
import { Appointment, AppointmentDetails } from "../models/appointment";
import {
  approveMisaEnvironmentalAppointment,
  deleteAppointment,
  getAppointmentDetails,
  getAppointments,
  validateMisaEnvironmentalAppointment,
} from "../services/AppointmentService";

export function useAppointments(misa: boolean = false) {
  return useQuery<Appointment[], Error>(["appointments", misa], () =>
    getAppointments(misa)
  );
}

export function useAppointmentDetails(
  appointment_type: string,
  appointmentId: string,
  misa: boolean
) {
  return useQuery<AppointmentDetails, Error>(
    ["appointment", appointment_type, appointmentId],
    () => getAppointmentDetails({ appointment_type, appointmentId, misa })
  );
}

export function useAppointmentApproval() {
  const queryClient = useQueryClient();

  const { isLoading, mutateAsync } = useMutation(
    approveMisaEnvironmentalAppointment,
    {
      onSuccess: () => {
        // TODO - define strategy - will impact backend response
        // Updates from Mutation Responses (https://react-query.tanstack.com/guides/updates-from-mutation-responses)
        // Invalidation from Mutations - (https://react-query.tanstack.com/guides/invalidations-from-mutations)
        queryClient.invalidateQueries("appointments");
        queryClient.invalidateQueries("appointment"); // we might specify id
      },
    }
  );

  return { isApproving: isLoading, approveAppointment: mutateAsync };
}

export function useAppointmentValidation() {
  const queryClient = useQueryClient();

  const { isLoading, mutateAsync } = useMutation(
    validateMisaEnvironmentalAppointment,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("appointments");
        queryClient.invalidateQueries("appointment");
      },
    }
  );

  return { isValidating: isLoading, validateAppointment: mutateAsync };
}

export function useAppointmentDeletion() {
  const queryClient = useQueryClient();

  const { isLoading, mutateAsync } = useMutation(
    ({
      appointment_type,
      appointmentId,
    }: {
      appointment_type: string;
      appointmentId: number;
    }) => deleteAppointment({ appointmentId, appointment_type }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("appointments");
      },
    }
  );

  return { isDeleting: isLoading, deleteAppointment: mutateAsync };
}
