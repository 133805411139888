import clsx from "clsx";
import { ReactNode } from "react";

type EventProps = {
  selected: boolean;
  children: ReactNode;
  onClick: () => void;
};

export default function Event({ selected, children, onClick }: EventProps) {
  const style = clsx({
    "border-lns-timeslot bg-lns-timeslot": !selected,
    "border-lns-timeslot-selected bg-lns-timeslot-selected": selected,
  });

  return (
    <button
      onClick={onClick}
      type="button"
      className={`text-lns-white w-full p-1 px-3 border max-h-10 mb-0 overflow-hidden rounded text-center block ${style}`}
    >
      {children}
    </button>
  );
}
