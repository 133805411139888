import { Disclosure, Transition } from "@headlessui/react";
import { CaretDownFill } from "react-bootstrap-icons";

type DisclosurePanelProps = {
  children: React.ReactNode;
  title: string;
};

export default function DisclosurePanel({
  children,
  title,
}: DisclosurePanelProps) {
  return (
    <Disclosure>
      {({ open }) => (
        <div className="border rounded-lg border-lns-gray-border">
          <Disclosure.Button className="flex justify-between items-center w-full p-4 lg:p-6">
            <span className="font-bold uppercase">{title}</span>
            <CaretDownFill
              className={`${open ? "transform rotate-180" : ""}`}
            />
          </Disclosure.Button>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform opacity-0"
            enterTo="transform opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform opacity-100"
            leaveTo="transform opacity-0"
          >
            <Disclosure.Panel className="pb-4 px-4 lg:pb-6 lg:px-6" static>
              {children}
            </Disclosure.Panel>
          </Transition>
        </div>
      )}
    </Disclosure>
  );
}
