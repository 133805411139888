import { ReactComponent as Next } from "./next.svg";
import { ReactComponent as NextDisabled } from "./next_disabled.svg";
import { ReactComponent as Prev } from "./prev.svg";
import { ReactComponent as PrevDisabled } from "./prev_disabled.svg";

type ControlsProps = {
  children: React.ReactNode;
  canGoNext: boolean;
  canGoPrev: boolean;
  onClickNext: () => void;
  onClickPrev: () => void;
};

export default function Controls({
  children,
  canGoNext,
  canGoPrev,
  onClickNext,
  onClickPrev,
}: ControlsProps) {
  return (
    <div className="flex items-center justify-between pt-0 pb-4">
      <button
        onClick={onClickPrev}
        disabled={!canGoPrev}
        className="rounded text-lns-primary p-0.5 -ml-0.5"
        data-testid="ControlPrev"
        type="button"
      >
        {!canGoPrev ? <PrevDisabled /> : <Prev />}
      </button>

      <span className="flex-1 font-bold text-center uppercase">{children}</span>

      <button
        onClick={onClickNext}
        disabled={!canGoNext}
        className="rounded text-lns-primary p-0.5 -mr-0.5"
        data-testid="ControlNext"
        type="button"
      >
        {!canGoNext ? <NextDisabled /> : <Next />}
      </button>
    </div>
  );
}
