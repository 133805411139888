import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import { ReactComponent as GoogleCalendarIcon } from "../../components/icons/google_calendar_icon.svg";
import { ReactComponent as GoogleMapsIcon } from "../../components/icons/google_maps_icon.svg";
import { ReactComponent as OutlookCalendarIcon } from "../../components/icons/outlook_calendar_icon.svg";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";
import PageTitle from "../../components/Typography/PageTitle";
import { GOOGLE_PLACE_ID } from "../../config";
import { useApp } from "../../contexts/AppContext";
import { formatISO } from "../../utils/generic";
import {
  addToGoogleCalendar,
  addToOutlookCalendar,
  searchGoogleMaps,
} from "../../utils/social";

type ConfirmationStepProps = {
  children: React.ReactNode;
  title: string;
};

export default function ConfirmationStep({
  children,
  title,
}: ConfirmationStepProps) {
  const { t } = useTranslation();

  return (
    <Container padded>
      <div className="flex flex-col mb-4 lg:flex-row lg:mb-0">
        <div className="flex-auto">
          <PageTitle>{title}</PageTitle>
        </div>
        <Button variant="primary" as={Link} to={"/"}>
          {t("components.buttons.back_to_home")}
        </Button>
      </div>
      <div className="flex flex-col gap-6 lg:flex-row">{children}</div>
    </Container>
  );
}

type ConfirmationStepSummaryProps = {
  endDatetime: string;
  instructionKey: string;
  startDatetime: string;
  title: string;
};

export function ConfirmationStepSummary({
  endDatetime,
  instructionKey,
  startDatetime,
  title,
}: ConfirmationStepSummaryProps) {
  const { profile } = useApp();

  const address =
    profile?.attributes &&
    `${profile.attributes.street ? profile.attributes.street[0] : ""}, ${
      profile.attributes.zip ? profile.attributes.zip[0] : ""
    } ${profile.attributes.city ? profile.attributes.city[0] : ""}`;

  return (
    <Panel className="flex-1">
      <h2 className="text-2xl font-bold mb-7">{title}</h2>
      <Trans
        i18nKey={instructionKey}
        components={{
          ul: <ul className="list-disc list-inside ml-5 space-y-4 lg:ml-6" />,
        }}
        values={{
          address,
          date: new Date(startDatetime),
          date_short: formatISO(startDatetime, "(d-MM-yyyy)"),
          from: formatISO(startDatetime, "H:mm"),
          to: formatISO(endDatetime, "H:mm"),
        }}
      />
    </Panel>
  );
}

type ConfirmationStepExtraProps = {
  children: React.ReactNode;
};

export function ConfirmationStepExtra({
  children,
}: ConfirmationStepExtraProps) {
  return <div className="flex flex-col flex-1 gap-y-6">{children}</div>;
}

export function ConfirmationStepJoinUs() {
  const { t } = useTranslation();
  return (
    <Panel className="flex-1 space-y-6">
      <h2 className="text-2xl font-bold">
        {t("pages.booking.shared.steps.confirmation.howtojoin_panel.title")}
      </h2>
      <Trans
        i18nKey="pages.booking.shared.steps.confirmation.howtojoin_panel.instructions"
        parent="div"
      />
      <Button
        as="a"
        variant="outline-primary"
        icon={<GoogleMapsIcon />}
        className="w-full lg:w-auto"
        href={searchGoogleMaps(
          "LNS Laboratoire National de Santé",
          GOOGLE_PLACE_ID
        )}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t("components.buttons.google_maps")}
      </Button>
    </Panel>
  );
}

export function ConfirmationStepContact() {
  const { t } = useTranslation();

  return (
    <Panel className="space-y-6">
      <h2 className="text-2xl font-bold">
        {t(
          "pages.booking.shared.steps.confirmation.youhavequestion_panel.title"
        )}
      </h2>
      <Trans
        i18nKey="pages.booking.shared.steps.confirmation.youhavequestion_panel.instructions"
        parent="div"
      />
    </Panel>
  );
}

type ConfirmationStepAddToCalendarProps = {
  endDatetime: string;
  startDatetime: string;
  subject: string;
};

export function ConfirmationStepAddToCalendar({
  endDatetime,
  startDatetime,
  subject,
}: ConfirmationStepAddToCalendarProps) {
  const { t } = useTranslation();

  return (
    <Panel>
      <div className="space-y-6">
        <h2 className="text-2xl font-bold">
          {t("pages.booking.shared.steps.confirmation.calendar_panel.title")}
        </h2>
        <Trans
          i18nKey="pages.booking.shared.steps.confirmation.calendar_panel.instructions"
          parent="div"
        />
        <div className="flex flex-row flex-wrap gap-3">
          <Button
            as="a"
            variant="outline-primary"
            icon={<GoogleCalendarIcon />}
            className="w-full lg:w-auto"
            href={addToGoogleCalendar(subject, startDatetime, endDatetime)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Calendar
          </Button>
          <Button
            as="a"
            variant="outline-primary"
            icon={<OutlookCalendarIcon />}
            className="w-full lg:w-auto"
            href={addToOutlookCalendar(subject, startDatetime, endDatetime)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Outlook Calendar
          </Button>
        </div>
      </div>
    </Panel>
  );
}
