import { useTranslation } from "react-i18next";

type I18nLabelProps = {
  label: string;
};

export const I18nLabel = ({ label }: I18nLabelProps) => {
  const { t } = useTranslation();
  return <span>{t(label)}</span>;
};
