import clsx from "clsx";
import { ReactNode } from "react";

type ContainerProps = {
  children: ReactNode;
  padded?: boolean;
  className?: string;
};

export default function Container({
  children,
  className,
  padded,
}: ContainerProps) {
  const classees = clsx({ "px-3": padded });

  return (
    <div className={`container mx-auto ${classees} ${className}`}>
      {children}
    </div>
  );
}
