import clsx from "clsx";
import { addDays } from "date-fns";
import { useTranslation } from "react-i18next";
import { findEventsForDay } from "../../../utils/calendar";
import { useCalendar } from "../Calendar";
import Controls from "../Controls";
import EventList from "../EventList";

export default function ViewModeDay() {
  const {
    date,
    events,
    selectedEvent,
    setDate,
    minDate,
    maxDate,
    onEventClick,
  } = useCalendar();
  const { t } = useTranslation();
  const prevDayAvailable = minDate ? addDays(date, -1) >= minDate : true;
  const nextDayAvailable = maxDate ? addDays(date, 1) <= maxDate : true;
  const dayEvents = findEventsForDay(date, events);
  const hasDayEvents = !!dayEvents.length;

  return (
    <>
      <Controls
        onClickPrev={() => setDate(addDays(date, -1))}
        onClickNext={() => setDate(addDays(date, 1))}
        canGoPrev={prevDayAvailable}
        canGoNext={nextDayAvailable}
      >
        {t("components.calendar.day_month_year", { date })}
      </Controls>

      <div className={clsx("Day")}>
        <div className="Content">
          {!hasDayEvents && (
            <p className="text-center">
              {t("components.calendar.no_timeslots_available")}
            </p>
          )}

          {hasDayEvents && (
            <EventList
              events={dayEvents}
              selectedEvent={selectedEvent}
              onEventClick={onEventClick}
            />
          )}
        </div>
      </div>
    </>
  );
}
