import { useEffect } from "react";
import { Route, RouteProps, useLocation } from "react-router-dom";

type ScrollToTopRouteProps = RouteProps & {
  when: string | boolean;
};

export default function ScrollToTopRoute({
  path,
  when,
  ...rest
}: ScrollToTopRouteProps) {
  const { pathname } = useLocation();

  useEffect(() => {
    when && window.scrollTo(0, 0);
  }, [when, pathname, path]);

  return <Route {...rest} />;
}
