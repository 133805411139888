import { useTranslation } from "react-i18next";
import InputWithAnswer from "../../../components/Inputs/InputWithAnswer";
import TextArea from "../../../components/Inputs/TextArea";
import TextField from "../../../components/Inputs/TextField";
import { formatISO } from "../../../utils/generic";

type SurveyCompanyEnvironmentalProps = {
  errors?: { [x: string]: any };
  register?: any;
  answers?: any;
};

export default function SurveyCompanyEnvironmental({
  answers,
  errors,
  register = () => {}, // default to empty object for survey results page
}: SurveyCompanyEnvironmentalProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col grid-cols-4 gap-8 lg:gap-y-12 lg:grid lg:items-end">
      <InputWithAnswer
        answer="phone"
        label={t("pages.booking.environmental.steps.survey.company_form.phone")}
        answers={answers}
      >
        <TextField
          {...register("appointment.phone", { required: true })}
          error={errors?.appointment?.phone && t("components.form.required")}
          required
        />
      </InputWithAnswer>

      <InputWithAnswer
        answer="mail"
        label={t("pages.booking.environmental.steps.survey.company_form.mail")}
        answers={answers}
      >
        <TextField
          {...register("appointment.mail", { required: true })}
          error={errors?.appointment?.mail && t("components.form.required")}
          required
        />
      </InputWithAnswer>

      <InputWithAnswer
        answer="contact_worker_doctor"
        label={t(
          "pages.booking.environmental.steps.survey.company_form.contact_worker_doctor"
        )}
        answers={answers}
      >
        <TextField
          {...register("appointment.contact_worker_doctor", { required: true })}
          error={
            errors?.appointment?.contact_worker_doctor &&
            t("components.form.required")
          }
          required
        />
      </InputWithAnswer>

      <InputWithAnswer
        answer="survey.business_sector"
        label={t(
          "pages.booking.environmental.steps.survey.company_form.business_sector"
        )}
        answers={answers}
      >
        <TextField {...register("appointment.survey.business_sector")} />
      </InputWithAnswer>

      <InputWithAnswer
        answer="survey.business_date"
        format={(date) => formatISO(date, "d-MM-yyyy")}
        label={t(
          "pages.booking.environmental.steps.survey.company_form.business_date"
        )}
        answers={answers}
      >
        <TextField
          {...register("appointment.survey.business_date")}
          type="date"
        />
      </InputWithAnswer>

      <InputWithAnswer
        answer="survey.number_employees"
        label={t(
          "pages.booking.environmental.steps.survey.company_form.number_employees"
        )}
        answers={answers}
      >
        <TextField {...register("appointment.survey.number_employees")} />
      </InputWithAnswer>

      <InputWithAnswer
        answer="survey.room_description"
        label={t(
          "pages.booking.environmental.steps.survey.company_form.room_description"
        )}
        answers={answers}
      >
        <TextField
          {...register("appointment.survey.room_description", {
            required: true,
          })}
          error={
            errors?.appointment?.survey?.room_description &&
            t("components.form.required")
          }
          required
        />
      </InputWithAnswer>

      <InputWithAnswer
        answer="enterprise_name"
        label={t(
          "pages.booking.environmental.steps.survey.company_form.enterprise_name"
        )}
        answers={answers}
      >
        <TextField
          {...register("appointment.enterprise_name", { required: true })}
          error={
            errors?.appointment?.enterprise_name &&
            t("components.form.required")
          }
          required
        />
      </InputWithAnswer>

      <InputWithAnswer
        answer="enterprise_vat"
        label={t(
          "pages.booking.environmental.steps.survey.company_form.enterprise_vat"
        )}
        answers={answers}
      >
        <TextField
          {...register("appointment.enterprise_vat", { required: true })}
          error={
            errors?.appointment?.enterprise_vat && t("components.form.required")
          }
          required
        />
      </InputWithAnswer>

      <div className="flex flex-col grid-cols-4 col-span-4 row-span-1 gap-4 gap-x-8 lg:grid">
        <h2 className="col-span-4 font-medium">
          {t("pages.booking.environmental.steps.survey.company_form.address")}
        </h2>

        <InputWithAnswer
          answer="street"
          label={t(
            "pages.booking.environmental.steps.survey.company_form.street"
          )}
          answers={answers}
        >
          <TextField
            {...register("appointment.street", { required: true })}
            error={errors?.appointment?.street && t("components.form.required")}
            required
          />
        </InputWithAnswer>

        <div className="flex flex-row gap-x-8">
          <InputWithAnswer
            answer="number"
            label={t(
              "pages.booking.environmental.steps.survey.company_form.number"
            )}
            answers={answers}
          >
            <TextField
              {...register("appointment.number", { required: true })}
              error={
                errors?.appointment?.number && t("components.form.required")
              }
              required
            />
          </InputWithAnswer>

          <InputWithAnswer
            answer="zip"
            label={t(
              "pages.booking.environmental.steps.survey.company_form.zip"
            )}
            answers={answers}
          >
            <TextField
              {...register("appointment.zip", { required: true })}
              error={errors?.appointment?.zip && t("components.form.required")}
              required
            />
          </InputWithAnswer>
        </div>

        <div className="col-span-1">
          <InputWithAnswer
            answer="city"
            label={t(
              "pages.booking.environmental.steps.survey.company_form.city"
            )}
            answers={answers}
          >
            <TextField
              {...register("appointment.city", { required: true })}
              error={errors?.appointment?.city && t("components.form.required")}
              required
            />
          </InputWithAnswer>
        </div>
      </div>

      <div className="flex flex-col grid-cols-4 col-span-4 gap-4 gap-x-8 lg:grid">
        <h2 className="col-span-4 font-medium">
          {t(
            "pages.booking.environmental.steps.survey.company_form.billing_address"
          )}
        </h2>

        <InputWithAnswer
          answer="billing_street"
          label={t(
            "pages.booking.environmental.steps.survey.company_form.street"
          )}
          answers={answers}
        >
          <TextField {...register("appointment.billing_street")} />
        </InputWithAnswer>

        <div className="flex flex-row gap-x-8">
          <InputWithAnswer
            answer="billing_number"
            label={t(
              "pages.booking.environmental.steps.survey.company_form.number"
            )}
            answers={answers}
          >
            <TextField {...register("appointment.billing_number")} />
          </InputWithAnswer>

          <InputWithAnswer
            answer="billing_zip"
            label={t(
              "pages.booking.environmental.steps.survey.company_form.zip"
            )}
            answers={answers}
          >
            <TextField {...register("appointment.billing_zip")} />
          </InputWithAnswer>
        </div>

        <div className="col-span-1">
          <InputWithAnswer
            answer="billing_city"
            label={t(
              "pages.booking.environmental.steps.survey.company_form.city"
            )}
            answers={answers}
          >
            <TextField {...register("appointment.billing_city")} />
          </InputWithAnswer>
        </div>
      </div>

      <div className="col-span-4">
        <InputWithAnswer
          answer="survey.problems_enterprise_description"
          label={t(
            "pages.booking.environmental.steps.survey.company_form.problems_enterprise_description"
          )}
          answers={answers}
        >
          <TextArea
            {...register("appointment.survey.problems_enterprise_description", {
              required: true,
            })}
            error={
              errors?.appointment?.survey?.problems_enterprise_description &&
              t("components.form.required")
            }
            helper={t("components.form.max_x_characters", { x: "500" })}
            required
            rows="4"
          />
        </InputWithAnswer>
      </div>
    </div>
  );
}
