import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment } from "react";
import { CaretDownFill, Check } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

type DropDownFieldProps = {
  error?: string;
  label?: string;
  name: string;
  includeBlank?: boolean;
  options: Option[];
  value?: Option;
  onChange: (value: Option) => void;
  required?: boolean;
};

export interface Option {
  value: number | string;
  label: string;
  [x: string]: any;
}

export default function DropDownField({
  error,
  label,
  value,
  onChange,
  includeBlank,
  options,
  required,
}: DropDownFieldProps) {
  const { t } = useTranslation();
  const blank: Option = { value: -1, label: t("components.dropdown.blank") };
  const defaultSelection = includeBlank ? blank : options[0];
  const selected =
    (value &&
      options.find((o) =>
        value?.value
          ? o.value === value.value
          : String(o.value) === String(value)
      )) ||
    defaultSelection;

  return (
    <div className="relative flex flex-col flex-auto gap-y-2">
      {label && (
        <label
          className={clsx(
            "flex-shrink-0",
            error ? "text-lns-error" : "text-lns-gray-text"
          )}
        >
          {label}
          {required && " *"}
        </label>
      )}

      <Listbox value={selected} onChange={onChange}>
        <div className="relative">
          <Listbox.Button
            className={clsx(
              "relative block w-full p-2 px-3 text-left border rounded",
              error ? "border-lns-error" : "border-lns-gray-border"
            )}
          >
            <span className="block truncate">{selected.label}</span>
            <span className="absolute inset-y-0 flex items-center pointer-events-none right-2 text-lns-gray-text">
              <CaretDownFill size={12} />
            </span>
          </Listbox.Button>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-50 w-full py-1 mt-1 overflow-auto rounded shadow-lg bg-lns-white max-h-60 ring-1 ring-lns-gray-border ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((option, optionIdx) => (
                <Listbox.Option
                  key={optionIdx}
                  className={({ active }) =>
                    `${active ? "bg-lns-gray-background" : ""}
                    text-lns-black cursor-pointer select-none relative py-2 pl-10 pr-4`
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`${
                          selected ? "font-medium" : "font-normal"
                        } block truncate`}
                      >
                        {option.label}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <Check size={20} />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {error && (
        <span className="absolute left-0 self-start -bottom-6 text-lns-error text-xs">
          {error}
        </span>
      )}
    </div>
  );
}
