import { useTranslation } from "react-i18next";
import InputWithAnswer from "../../../components/Inputs/InputWithAnswer";
import RadioField from "../../../components/Inputs/RadioField";
import RadioGroupField from "../../../components/Inputs/RadioGroupField";
import TextField from "../../../components/Inputs/TextField";
import UploadArea from "../../../components/Inputs/UploadArea";
import { formatISO } from "../../../utils/generic";

type SurveyAllergologicalProps = {
  errors?: { [x: string]: any };
  isChildConsult: boolean;
  register?: any;
  answers?: any;
  onPrescriptionUpload?: (file: any) => void;
};

export default function SurveyAllergological({
  answers,
  errors,
  isChildConsult,
  onPrescriptionUpload,
  register = () => {}, // default to empty object for survey results page
}: SurveyAllergologicalProps) {
  const { t } = useTranslation();
  return (
    <div className="grid grid-cols-1 gap-8 lg:grid-cols-12 lg:gap-y-12">
      <div className="lg:col-span-3">
        <InputWithAnswer
          answer="survey.child_consult"
          answers={answers}
          label={t("pages.booking.allergology.steps.survey.form.child_consult")}
          options={[
            {
              label: t("shared.yes"),
              value: "yes",
            },
            {
              label: t("shared.no"),
              value: "no",
            },
          ]}
        >
          {({ options, label }) => (
            <RadioGroupField
              error={
                errors?.appointment?.survey?.child_consult &&
                t("components.form.required")
              }
              horizontal
              label={label}
              required
            >
              {options?.map((option: { label: string; value: string }) => (
                <RadioField
                  {...register("appointment.survey.child_consult", {
                    required: true,
                  })}
                  key={option.label}
                  label={option.label}
                  value={option.value}
                />
              ))}
            </RadioGroupField>
          )}
        </InputWithAnswer>
      </div>

      {isChildConsult ? (
        <>
          <div className="lg:col-span-3">
            <InputWithAnswer
              answer="survey.child_name"
              label={t(
                "pages.booking.allergology.steps.survey.form.child_name"
              )}
              answers={answers}
            >
              <TextField {...register("appointment.survey.child_name")} />
            </InputWithAnswer>
          </div>

          <div className="lg:col-span-3">
            <InputWithAnswer
              answer="survey.child_birthdate"
              format={(date) => formatISO(date, "d-MM-yyyy")}
              label={t(
                "pages.booking.allergology.steps.survey.form.child_birthdate"
              )}
              answers={answers}
            >
              <TextField
                {...register("appointment.survey.child_birthdate")}
                type="date"
              />
            </InputWithAnswer>
          </div>

          <div className="lg:col-span-3">
            <InputWithAnswer
              answer="survey.child_number"
              label={t(
                "pages.booking.allergology.steps.survey.form.child_number"
              )}
              answers={answers}
            >
              <TextField {...register("appointment.child_number")} />
            </InputWithAnswer>
          </div>
        </>
      ) : (
        <div className="hidden lg:block lg:col-span-9" />
      )}

      <div className="lg:col-span-3">
        <InputWithAnswer
          answer="survey.medicine_number"
          label={t(
            "pages.booking.allergology.steps.survey.form.medicine_number"
          )}
          answers={answers}
        >
          <TextField
            {...register("appointment.survey.medicine_number", {
              min: 1,
              max: 6,
              required: true,
              valueAsNumber: true,
            })}
            error={
              errors?.appointment?.survey?.medicine_number?.type === "min" ||
              errors?.appointment?.survey?.medicine_number?.type === "max"
                ? t("pages.booking.allergology.steps.survey.errors.medicineMax")
                : errors?.appointment?.survey?.medicine_number &&
                  t("components.form.required")
            }
            required
            type="number"
          />
        </InputWithAnswer>
      </div>

      <div className="lg:col-span-3">
        <InputWithAnswer
          answer="survey.last_reaction_date"
          format={(date) => formatISO(date, "d-MM-yyyy")}
          label={t(
            "pages.booking.allergology.steps.survey.form.last_reaction_date"
          )}
          answers={answers}
        >
          <TextField
            {...register("appointment.survey.last_reaction_date", {
              required: true,
            })}
            type="date"
            required
          />
        </InputWithAnswer>
      </div>

      <div className="lg:col-span-6">
        <InputWithAnswer
          answer="survey.lymphocytic_test"
          answers={answers}
          label={t(
            "pages.booking.allergology.steps.survey.form.lymphocytic_test"
          )}
          options={[
            {
              label: t("shared.yes"),
              value: "yes",
            },
            {
              label: t("shared.no"),
              value: "no",
            },
          ]}
        >
          {({ options, label }) => (
            <RadioGroupField
              error={
                errors?.appointment?.survey?.lymphocytic_test &&
                t("components.form.required")
              }
              horizontal
              label={label}
              required
            >
              {options?.map((option: { label: string; value: string }) => (
                <RadioField
                  {...register("appointment.survey.lymphocytic_test", {
                    required: true,
                  })}
                  key={option.label}
                  label={option.label}
                  value={option.value}
                />
              ))}
            </RadioGroupField>
          )}
        </InputWithAnswer>
      </div>
      {!answers && (
        <div className="relative lg:col-span-6">
          <UploadArea
            id="prescription-upload"
            label="Prescription"
            onChange={onPrescriptionUpload}
          />
        </div>
      )}
    </div>
  );
}
