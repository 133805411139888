import { format, parseISO, Locale } from "date-fns";
import capitalize from "lodash/capitalize";
import BookingWizardConfigs from "../config.wizard";

export function getBookingWizardConfigByType(type: string) {
  return BookingWizardConfigs[
    type.toUpperCase() as keyof typeof BookingWizardConfigs
  ];
}

export function capitalizeFirstLetter(text?: string) {
  return capitalize(text);
}

export function formatISO(
  dateISO: string | Date,
  formatOption: string,
  options?: { locale?: Locale | undefined }
) {
  return format(
    typeof dateISO === "string" ? parseISO(dateISO) : dateISO,
    formatOption,
    options
  );
}
