import React, { useContext } from "react";
import {
  matchPath,
  Prompt,
  Redirect,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useSetState } from "react-use";
import { useQueryParams } from "../../hooks/useQueryParams";
import ScrollToTopRoute from "../Layout/ScrollToTopRoute";

export interface WizardContextProps {
  currentStep: Step;
  nextStep?: Step;
  prevStep?: Step;
  configs: WizardConfigs;
  values: FormValues;
  goToNextStep: (values?: FormValues) => Promise<any>;
  subscriber?: string | null;
}

export interface Step {
  /**
   * Route path
   */
  path: string;

  /**
   * Component for the route
   */
  Component: () => JSX.Element;

  /**
   * Allows the page to be refreshed
   */
  independent?: boolean;
}

export const WizardContext = React.createContext({} as WizardContextProps);

export function useWizard() {
  return useContext(WizardContext);
}

export interface FormValues {
  [key: string]: any;
}

export type WizardConfigs = {
  root: string;
  steps: Step[];
  initialValues?: FormValues;
};

type WizardProps = {
  configs: WizardConfigs;
};

type WizardState = {
  stepsDone: Step[];
  values: FormValues;
};

export default function Wizard({ configs }: WizardProps) {
  const history = useHistory();
  const queryParams = useQueryParams();
  const { root, steps, initialValues = {} } = configs;

  const [state, setState] = useSetState<WizardState>({
    stepsDone: [],
    values: initialValues,
  });

  const firstPath = steps[0].path;
  const firstStep = `${root}/${firstPath}`;
  const currentPath = useLocation().pathname;
  const currentStepIdx = steps.findIndex((s) => currentPath.endsWith(s.path));
  const currentStep = steps[currentStepIdx];
  const nextStep = steps[currentStepIdx + 1];
  const prevStep = steps[currentStepIdx - 1];
  const lastStep = steps[steps.length - 1];
  const shouldPrompt = currentStepIdx > 0 && currentStep !== lastStep;
  const isFirst = currentStepIdx === 0;
  const subscriber = queryParams.get("sub");
  const queryString = subscriber ? `?sub=${subscriber}` : "";
  const redirectToFirstStep =
    !currentStep ||
    (!currentStep?.independent && !isFirst && state.stepsDone.length === 0);

  function displayPrompMessage({ pathname }: { pathname: string }) {
    return matchPath(pathname, { path: root })
      ? true
      : "Are you sure you want to navigate away?";
  }

  async function goToNextStep(stepValues: FormValues = {}) {
    const mergedValues = { ...state.values, ...stepValues };
    const nextPath = `${nextStep.path}${queryString}`;

    setState({
      stepsDone: [...state.stepsDone, currentStep],
      values: mergedValues,
    });

    await Promise.resolve();

    // disable back button
    isFirst ? history.push(nextPath) : history.replace(nextPath);
  }

  if (redirectToFirstStep) {
    return (
      <Redirect
        from={root}
        exact
        to={{
          pathname: firstStep,
          search: queryString,
        }}
      />
    );
  }

  return (
    <WizardContext.Provider
      value={{
        currentStep,
        nextStep,
        prevStep,
        configs,
        values: state.values,
        goToNextStep,
        subscriber,
      }}
    >
      <Prompt when={shouldPrompt} message={displayPrompMessage} />

      <Route exact path={"/:bookingroot/:appointment_type/:step"}>
        <ScrollToTopRoute
          exact
          key={currentStep.path}
          component={currentStep.Component}
          when={true}
        />
      </Route>
    </WizardContext.Provider>
  );
}
