import { useQuery } from "react-query";
import { Timeslot } from "../models/timeslot";
import { getTimeslots } from "../services/TimeslotService";

export function useTimeslots(
  appointment_type: string,
  params?: { [key: string]: any }
) {
  return useQuery<Timeslot[], Error>(["timeslots", appointment_type], () =>
    getTimeslots({ appointment_type, params })
  );
}
