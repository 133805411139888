import range from "lodash/range";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DropDownField from "../../../components/Inputs/DropDownField";
import InputWithAnswer from "../../../components/Inputs/InputWithAnswer";
import RadioField from "../../../components/Inputs/RadioField";
import RadioGroupField from "../../../components/Inputs/RadioGroupField";
import TextArea from "../../../components/Inputs/TextArea";
import TextField from "../../../components/Inputs/TextField";

type SurveyPrivateEnvironmentalProps = {
  errors?: { [x: string]: any };
  register?: any;
  answers?: any;
  control?: any;
};

export default function SurveyPrivateEnvironmental({
  answers,
  errors,
  register = () => {}, // default to empty object for survey results page
  control,
}: SurveyPrivateEnvironmentalProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col grid-cols-4 gap-8 lg:gap-y-12 lg:grid lg:items-end">
      <InputWithAnswer
        answer="survey.building_type"
        answers={answers}
        label={t(
          "pages.booking.environmental.steps.survey.private_form.building_type"
        )}
        options={[
          {
            label: t(
              "pages.booking.environmental.steps.survey.private_form.building_type_values.apartment"
            ),
            value: "apartment",
          },
          {
            label: t(
              "pages.booking.environmental.steps.survey.private_form.building_type_values.house"
            ),
            value: "house",
          },
        ]}
      >
        {({ options, label }) => (
          <RadioGroupField
            error={
              errors?.appointment?.survey?.building_type &&
              t("components.form.required")
            }
            horizontal
            label={label}
            required
          >
            {options?.map((option: { label: string; value: string }) => (
              <RadioField
                {...register("appointment.survey.building_type", {
                  required: true,
                })}
                key={option.label}
                label={option.label}
                value={option.value}
              />
            ))}
          </RadioGroupField>
        )}
      </InputWithAnswer>

      <InputWithAnswer
        answer="survey.apartment_floor"
        answers={answers}
        label={t(
          "pages.booking.environmental.steps.survey.private_form.apartment_floor"
        )}
        options={range(1, 10).map((n) => ({
          value: String(n),
          label: String(n),
        }))}
      >
        {({ label, options }) => (
          <Controller
            name="appointment.survey.apartment_floor"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { name, value, onChange } }) => (
              <DropDownField
                error={
                  errors?.appointment?.survey?.apartment_floor &&
                  t("components.form.required")
                }
                value={value}
                onChange={(value) => onChange(value.value)}
                name={name}
                label={label}
                includeBlank
                options={options}
                required
              />
            )}
          />
        )}
      </InputWithAnswer>

      <InputWithAnswer
        answer="survey.household_type"
        answers={answers}
        label={t(
          "pages.booking.environmental.steps.survey.private_form.household_type"
        )}
        options={[
          {
            label: t(
              "pages.booking.environmental.steps.survey.private_form.household_type_values.owner"
            ),
            value: "owner",
          },
          {
            label: t(
              "pages.booking.environmental.steps.survey.private_form.household_type_values.tenant"
            ),
            value: "tenant",
          },
        ]}
      >
        {({ label, options }) => (
          <Controller
            name="appointment.survey.household_type"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { name, value, onChange } }) => (
              <DropDownField
                error={
                  errors?.appointment?.survey?.household_type &&
                  t("components.form.required")
                }
                value={value}
                onChange={(value) => onChange(value.value)}
                name={name}
                label={label}
                includeBlank
                options={options}
                required
              />
            )}
          />
        )}
      </InputWithAnswer>

      <InputWithAnswer
        answer="survey.rooms_number"
        answers={answers}
        label={t(
          "pages.booking.environmental.steps.survey.private_form.rooms_number"
        )}
        options={range(1, 10).map((n) => ({
          value: String(n),
          label: String(n),
        }))}
      >
        {({ label, options }) => (
          <Controller
            name="appointment.survey.rooms_number"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { name, value, onChange } }) => (
              <DropDownField
                error={
                  errors?.appointment?.survey?.rooms_number &&
                  t("components.form.required")
                }
                value={value}
                onChange={(value) => onChange(value.value)}
                name={name}
                label={label}
                includeBlank
                options={options}
                required
              />
            )}
          />
        )}
      </InputWithAnswer>

      <InputWithAnswer
        answer="survey.living_since_date"
        answers={answers}
        label={t(
          "pages.booking.environmental.steps.survey.private_form.living_since_date"
        )}
        options={range(
          new Date().getFullYear(),
          new Date().getFullYear() - 100,
          -1
        ).map((n) => ({ value: String(n), label: String(n) }))}
      >
        {({ label, options }) => (
          <Controller
            name="appointment.survey.living_since_date"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { name, value, onChange } }) => (
              <DropDownField
                error={
                  errors?.appointment?.survey?.living_since_date &&
                  t("components.form.required")
                }
                value={value}
                onChange={(value) => onChange(value.value)}
                name={name}
                label={label}
                includeBlank
                options={options}
                required
              />
            )}
          />
        )}
      </InputWithAnswer>

      <div>
        <label className="flex flex-col gap-2">
          <span className="text-lns-gray-text">
            {t(
              "pages.booking.environmental.steps.survey.private_form.people_number"
            )}
          </span>

          <div className="flex flex-row gap-x-4">
            <InputWithAnswer
              answer="survey.adult_number"
              answers={answers}
              label={t(
                "pages.booking.environmental.steps.survey.private_form.adult_number"
              )}
              horizontal
            >
              <TextField
                {...register("appointment.survey.adult_number", {
                  required: true,
                })}
                error={
                  errors?.appointment?.survey?.adult_number &&
                  t("components.form.required")
                }
                horizontal
                required
              />
            </InputWithAnswer>

            <InputWithAnswer
              answer="survey.children_number"
              answers={answers}
              label={t(
                "pages.booking.environmental.steps.survey.private_form.children_number"
              )}
              horizontal
            >
              <TextField
                {...register("appointment.survey.children_number", {
                  required: true,
                })}
                error={
                  errors?.appointment?.survey?.children_number &&
                  t("components.form.required")
                }
                horizontal
                required
              />
            </InputWithAnswer>
          </div>
        </label>
      </div>

      <InputWithAnswer
        answer="survey.ventilation_frequency"
        answers={answers}
        label={t(
          "pages.booking.environmental.steps.survey.private_form.ventilation_frequency"
        )}
        options={[
          {
            label: t(
              "pages.booking.environmental.steps.survey.private_form.ventilation_frequency_values.less_1"
            ),
            value: "less_1",
          },
          {
            label: t(
              "pages.booking.environmental.steps.survey.private_form.ventilation_frequency_values.more_1"
            ),
            value: "more_1",
          },
          {
            label: t(
              "pages.booking.environmental.steps.survey.private_form.ventilation_frequency_values.1_day"
            ),
            value: "1_day",
          },
          {
            label: t(
              "pages.booking.environmental.steps.survey.private_form.ventilation_frequency_values.2_to_3_day"
            ),
            value: "2_to_3_day",
          },
          {
            label: t(
              "pages.booking.environmental.steps.survey.private_form.ventilation_frequency_values.4_to_5_day"
            ),
            value: "4_to_5_day",
          },
          {
            label: t(
              "pages.booking.environmental.steps.survey.private_form.ventilation_frequency_values.every_day"
            ),
            value: "every_day",
          },
        ]}
        required
      >
        {({ label, options }) => (
          <Controller
            name="appointment.survey.ventilation_frequency"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { name, value, onChange } }) => (
              <DropDownField
                error={
                  errors?.appointment?.survey?.ventilation_frequency &&
                  t("components.form.required")
                }
                value={value}
                onChange={(value) => onChange(value.value)}
                name={name}
                label={label}
                includeBlank
                options={options}
                required
              />
            )}
          />
        )}
      </InputWithAnswer>

      <div></div>

      <InputWithAnswer
        answer="survey.mold_moisture_presence"
        answers={answers}
        label={t(
          "pages.booking.environmental.steps.survey.private_form.mold_moisture_presence"
        )}
        options={[
          {
            label: t(
              "pages.booking.environmental.steps.survey.private_form.mold_moisture_presence_values.yes"
            ),
            value: "yes",
          },
          {
            label: t(
              "pages.booking.environmental.steps.survey.private_form.mold_moisture_presence_values.no"
            ),
            value: "no",
          },
          {
            label: t(
              "pages.booking.environmental.steps.survey.private_form.mold_moisture_presence_values.dont_know"
            ),
            value: "dont_know",
          },
        ]}
      >
        {({ options, label }) => (
          <RadioGroupField
            error={
              errors?.appointment?.survey?.mold_moisture_presence &&
              t("components.form.required")
            }
            horizontal
            label={label}
            required
          >
            {options?.map((option: { label: string; value: string }) => (
              <RadioField
                {...register("appointment.survey.mold_moisture_presence", {
                  required: true,
                })}
                key={option.label}
                label={option.label}
                value={option.value}
              />
            ))}
          </RadioGroupField>
        )}
      </InputWithAnswer>

      <InputWithAnswer
        answer="survey.mold_moisture_since_date"
        answers={answers}
        label={t(
          "pages.booking.environmental.steps.survey.private_form.mold_moisture_since_date"
        )}
      >
        <TextField
          {...register("appointment.survey.mold_moisture_since_date")}
        />
      </InputWithAnswer>

      <div className="hidden md:col-span-2 md:block" />

      <InputWithAnswer
        answer="survey.smell_observation"
        answers={answers}
        label={t(
          "pages.booking.environmental.steps.survey.private_form.smell_observation"
        )}
        options={[
          {
            label: t(
              "pages.booking.environmental.steps.survey.private_form.smell_observation_values.yes"
            ),
            value: "yes",
          },
          {
            label: t(
              "pages.booking.environmental.steps.survey.private_form.smell_observation_values.no"
            ),
            value: "no",
          },
          {
            label: t(
              "pages.booking.environmental.steps.survey.private_form.smell_observation_values.dont_know"
            ),
            value: "dont_know",
          },
        ]}
      >
        {({ options, label }) => (
          <RadioGroupField
            error={
              errors?.appointment?.survey?.smell_observation &&
              t("components.form.required")
            }
            horizontal
            label={label}
            required
          >
            {options?.map((option: { label: string; value: string }) => (
              <RadioField
                {...register("appointment.survey.smell_observation", {
                  required: true,
                })}
                key={option.label}
                label={option.label}
                value={option.value}
              />
            ))}
          </RadioGroupField>
        )}
      </InputWithAnswer>

      <InputWithAnswer
        answer="survey.smell_since_date"
        answers={answers}
        label={t(
          "pages.booking.environmental.steps.survey.private_form.smell_since_date"
        )}
      >
        <TextField {...register("appointment.survey.smell_since_date")} />
      </InputWithAnswer>

      <div className="hidden md:col-span-2 md:block" />

      <div className="col-span-2">
        <InputWithAnswer
          answer="survey.problems_patient_description"
          answers={answers}
          label={t(
            "pages.booking.environmental.steps.survey.private_form.problems_patient_description"
          )}
        >
          <TextArea
            {...register("appointment.survey.problems_patient_description", {
              required: true,
            })}
            error={
              errors?.appointment?.survey?.problems_patient_description &&
              t("components.form.required")
            }
            helper={t("components.form.max_x_characters", { x: "500" })}
            required
            rows="4"
          />
        </InputWithAnswer>
      </div>
    </div>
  );
}
