import Keycloak from "keycloak-js";
import { DEBUG } from "../config";
import { misaProfile, patientProfile } from "../__mocks__/profile";

export const Roles = {
  MISA: "misa",
  PATIENT: "patient",
};

let _kc = Keycloak("/keycloak.json");

if (DEBUG) {
  const role = sessionStorage.getItem("demo-role");
  const isMisa = role === Roles.MISA;

  // Mock Keycloak for demo purpose
  _kc.init = () => Promise.resolve(true) as any;
  _kc.realmAccess = {
    roles: [role || Roles.PATIENT],
  };
  _kc.loadUserProfile = () => {
    return new Promise((resolve) =>
      setTimeout(() => resolve(isMisa ? misaProfile : patientProfile), 2000)
    ) as any;
  };
  _kc.accountManagement = () =>
    alert("(Demo) Going to account management") as any;
  _kc.logout = () => alert("(Demo) Logging out") as any;
  _kc.updateToken = () => Promise.resolve() as any;
  _kc.token = "123";
}

export const initKeycloak = (onAuthenticatedCallback: () => void) => {
  _kc
    .init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256",
    })
    .then((authenticated: any) => {
      if (authenticated) {
        onAuthenticatedCallback();
      } else {
        doLogin();
      }
    });
};

export const doAccountManagement = _kc.accountManagement;

export const doLogin = _kc.login;

export const doLogout = _kc.logout;

export const getToken = () => _kc.token;

export const isLoggedIn = () => !!_kc.token;

export const loadUserProfile = _kc.loadUserProfile;

export const updateToken = (successCallback: () => void) =>
  _kc.updateToken(30).then(successCallback).catch(doLogin);

export const hasRole = (roles: string[]) =>
  roles.some((role: string) => _kc.hasRealmRole(role));
