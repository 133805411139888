import { formatISO } from "date-fns";

export function addToGoogleCalendar(title: string, start: string, end: string) {
  const dtStart = formatISOShort(start);
  const dtEnd = formatISOShort(end);
  return `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
    title
  )}&dates=${dtStart}/${dtEnd}`;
}

export function addToOutlookCalendar(
  title: string,
  start: string,
  end: string
) {
  const dtStart = formatISOShort(start);
  const dtEnd = formatISOShort(end);
  return `https://outlook.live.com/owa/?path=/calendar/view/Month&rru=addevent&startdt=${dtStart}&enddt=${dtEnd}&subject=${title}`;
}

export function searchGoogleMaps(query: string, placeId: string) {
  return `https://www.google.com/maps/search/?api=1&query_place_id=${encodeURIComponent(
    placeId
  )}&query=${encodeURIComponent(query)}`;
}

function formatISOShort(date: string) {
  return formatISO(new Date(date), { format: "basic" });
}
