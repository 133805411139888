import configJson from "./config.json";

const configStore =
  process.env.NODE_ENV === "production"
    ? { ...configJson, ...process.env }
    : process.env;

export const DEBUG = configStore.REACT_APP_DEBUG === "true";
export const APPOINTMENT_TYPES = configStore.REACT_APP_APPOINTMENT_TYPES;
export const BASE_URL = configStore.REACT_APP_API_BASE_URL;
export const SUPPORTED_LANGUAGES = ["en", "fr", "de", "lu", "pt"];
export const FALLBACK_LANGUAGE = "en";
export const DEBUG_FETCH_DELAY = 750;

export const GOOGLE_PLACE_ID = "ChIJKYul7-Q2lUcRdffxGV2qqFs";
