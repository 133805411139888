import { useEffect } from "react";
import { TelephoneFill } from "react-bootstrap-icons";
import { Trans, useTranslation } from "react-i18next";
import { Link, NavLink, Switch, useParams } from "react-router-dom";
import Button from "../../components/Button/Button";
import Container from "../../components/Layout/Container";
import ScrollToTopRoute from "../../components/Layout/ScrollToTopRoute";
import Loading from "../../components/Loading/Loading";
import Panel from "../../components/Panel/Panel";
import PageTitle from "../../components/Typography/PageTitle";
import { useApp } from "../../contexts/AppContext";
import { useAppointmentDetails } from "../../hooks/useAppointments";
import useBreakpoints from "../../hooks/useBreakpoints";
import GeneralInfoTab from "./GeneralInfoTab";
import SummaryTab from "./SummaryTab";

type AppointmentDetailsPageParams = {
  appointmentId: string;
  appointment_type: string;
};

export default function AppointmentDetailsPage() {
  const { isMisa = false, isPatient = true, showError, hideError } = useApp();
  const { t } = useTranslation();
  const { appointmentId, appointment_type } =
    useParams<AppointmentDetailsPageParams>();
  const {
    isLoading,
    data: appointment,
    error,
  } = useAppointmentDetails(appointment_type, appointmentId, isMisa);

  const [isMobile] = useBreakpoints();

  useEffect(() => {
    error ? showError(error) : hideError();
  }, [error, showError, hideError]);

  return (
    <>
      <Container padded>
        <div className="flex flex-col mb-6 lg:flex-row lg:mb-3">
          <div className="flex-1">
            <PageTitle>
              {appointment?.reference}{" "}
              {appointment && (
                <>
                  {" "}
                  -{" "}
                  {isPatient ? (
                    <Trans
                      i18nKey={`models.appointment.analyzes.${appointment.appointment_type}`}
                    />
                  ) : appointment.partner_id ? (
                    `${appointment.partner_id?.firstname} ${appointment.partner_id?.lastname}`
                  ) : (
                    appointment.reference
                  )}
                </>
              )}
            </PageTitle>
          </div>

          <Button
            variant="outline-primary"
            as={Link}
            className="w-full lg:w-auto"
            to="/"
          >
            {t("components.buttons.back_to_home")}
          </Button>
        </div>
      </Container>

      {isLoading && (
        <Loading message={t("pages.appointment_details.loading")} />
      )}

      {appointment && (
        <div className="flex flex-col">
          {isPatient && (
            <Container padded>
              <div className="flex flex-row gap-2 border-b border-lns-gray-border">
                <NavLink
                  exact
                  to={`/analyses/${appointment_type}/${appointmentId}`}
                  activeClassName="active-tab"
                  className="flex items-center -mb-1 border rounded rounded-bl-none rounded-br-none -mb-1px bg-lns-white border-lns-gray-border"
                >
                  <Button
                    variant="outline-primary"
                    className="border-none"
                    as="span"
                  >
                    {t("pages.appointment_details.tabs.general_information")}
                  </Button>
                </NavLink>
                <NavLink
                  exact
                  to={`/analyses/${appointment_type}/${appointmentId}/summary`}
                  activeClassName="active-tab"
                  className="flex items-center border rounded rounded-bl-none rounded-br-none -mb-1px bg-lns-white border-lns-gray-border"
                >
                  <Button
                    variant="outline-primary"
                    className="border-none"
                    as={"span"}
                  >
                    {t("pages.appointment_details.tabs.summary")}
                  </Button>
                </NavLink>
              </div>
            </Container>
          )}

          <Switch>
            <ScrollToTopRoute
              when={isMobile}
              exact
              path="/analyses/:appointment_type/:appointmentId"
            >
              <GeneralInfoTab appointment={appointment} />
            </ScrollToTopRoute>

            <ScrollToTopRoute
              when={isMobile}
              exact
              path="/analyses/:appointment_type/:appointmentId/summary"
            >
              <SummaryTab appointment={appointment} />
            </ScrollToTopRoute>
          </Switch>

          {isPatient && (
            <Container padded className="mt-6">
              <Panel>
                <div className="flex flex-row items-center gap-3">
                  <div className="text-lns-gray-text">
                    <TelephoneFill />
                  </div>
                  <p>{t("pages.appointment_details.contact_instructions")}</p>
                </div>
              </Panel>
            </Container>
          )}
        </div>
      )}
    </>
  );
}
