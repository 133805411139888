import { I18nLabel } from "./components/Breadcrumb/I18nLabel";

const HIDE_BREADCRUMB = null;

const hideBreadcrumbsForRoutes = [
  { path: "/booking" },
  { path: "/booking/:appointment_type/welcome" },
  { path: "/analyses" },
  { path: "/analyses/:appointment_type" },
  {
    path: "/analyses/:appointment_type/:appointmentId/summary",
  },
].map((b) => ({ ...b, breadcrumb: HIDE_BREADCRUMB }));

const breadcrumbsRoutes = [
  { path: "/", props: { label: "pages.your_analysis.breadcrumb" } },
  {
    path: "/booking/toxicology",
    props: { label: "pages.booking.toxicological.breadcrumb" },
  },
  {
    path: "/booking/biological",
    props: { label: "pages.booking.biological.breadcrumb" },
  },
  {
    path: "/booking/environmental",
    props: { label: "pages.booking.environmental.breadcrumb" },
  },
  {
    path: "/booking/:appointment_type/get-info",
    props: { label: "pages.booking.shared.breadcrumbs.get_info" },
  },
  {
    path: "/booking/:appointment_type/booking",
    props: { label: "pages.booking.shared.steps.calendar.breadcrumb" },
  },
  {
    path: "/booking/:appointment_type/payment",
    props: { label: "pages.booking.toxicological.steps.payment.breadcrumb" },
  },
  {
    path: "/booking/:appointment_type/confirmation",
    props: { label: "pages.booking.shared.steps.confirmation.breadcrumb" },
  },
].map((b) => ({ ...b, breadcrumb: I18nLabel }));

const config = [...hideBreadcrumbsForRoutes, ...breadcrumbsRoutes];

export default config;
