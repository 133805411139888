import isEmpty from "lodash/isEmpty";
import { FileEarmarkArrowDown } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { Invoice } from "../../models/invoice";
import { downloadAppointmentInvoice } from "../../services/AppointmentService";
import Button from "../Button/Button";

type InvoiceListProps = {
  invoices?: Invoice[];
};

export default function InvoiceList({ invoices = [] }: InvoiceListProps) {
  const { t } = useTranslation();
  const hasInvoices = !isEmpty(invoices);

  if (!hasInvoices) {
    return <p>{t("pages.appointment_details.no_invoices")}</p>;
  }

  return (
    <div className="flex flex-col gap-y-3">
      {invoices.map((invoice, idx) => (
        <div
          key={idx}
          className="flex flex-col lg:items-center lg:flex-row gap-y-3"
        >
          <p className="flex-auto">{invoice.name}</p>
          <Button
            className="w-full lg:flex-none lg:w-min"
            icon={<FileEarmarkArrowDown />}
            onClick={() =>
              downloadAppointmentInvoice({
                filename: invoice.name,
                invoiceId: invoice.id,
              })
            }
          >
            {t("components.buttons.download_invoice")}
          </Button>
        </div>
      ))}
    </div>
  );
}
