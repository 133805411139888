import { Route, Switch } from "react-router-dom";
import { useApp } from "../../contexts/AppContext";
import AnalysesPage from "../../pages/AnalysesPage";
import AppointmentDetailsPage from "../../pages/AppointmentDetails/AppointmentDetailsPage";
import BookingPage from "../../pages/BookingPage";
import Alert from "../Alert/Alert";
import AppointmentRoute from "../Appointment/AppointmentRoute";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Container from "./Container";
import Footer from "./Footer";
import Header from "./Header";
import Main from "./Main";

export default function Layout() {
  const { alert, hideError } = useApp();

  return (
    <>
      <div className="border-b border-lns-gray-border">
        <Container padded>
          <Header />
        </Container>
      </div>

      <Container padded>
        <Breadcrumb />
      </Container>

      <Container padded>
        {alert && <Alert {...alert} onHide={hideError} />}
      </Container>

      <Main>
        <Switch>
          <Route exact path="/" children={<AnalysesPage />} />
          <Route
            path="/booking/:appointment_type"
            children={
              <AppointmentRoute>
                <BookingPage />
              </AppointmentRoute>
            }
          />
          <Route
            path="/analyses/:appointment_type/:appointmentId"
            children={
              <AppointmentRoute>
                <AppointmentDetailsPage />
              </AppointmentRoute>
            }
          />
        </Switch>
      </Main>

      <Container padded>
        <Footer />
      </Container>
    </>
  );
}
