import { Appointment, AppointmentDetails } from "../models/appointment";
import timeslots, { buildDate } from "./timeslots";

export const appointmentDetails: AppointmentDetails[] = [
  {
    id: 1,
    reference: "TOX - 12345",
    appointment_type: "toxicology",
    create_date: new Date(),
    partner_id: { firstname: "Cameron", lastname: "Rogers", id: 1 },
    start_datetime: timeslots[0].start_datetime,
    end_datetime: timeslots[0].end_datetime,
    state_full: "under_approval",
    document_count: 0,
    documents: [],
  },
  {
    id: 2,
    reference: "BIO - 12345",
    appointment_type: "biological",
    create_date: new Date(),
    partner_id: { firstname: "Cameron", lastname: "Rogers", id: 1 },
    start_datetime: timeslots[12].start_datetime,
    end_datetime: timeslots[12].end_datetime,
    state_full: "canceled",
    document_count: 1,
    documents: [
      {
        date: new Date(),
        s3_url: "https://localhost/technician-report.pdf",
        document_type: "Technician report",
        document_name: "Biological report 001",
      },
    ],
    survey: {
      height: "172 cm",
      weight: "85 kg",
      last_dentist_visit: "Less 1 month",
      last_amalgam: "Less 1 month",
      is_smoking: "Less than 10 cigarettes / day",
      reasons:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    },
  },
  {
    id: 1,
    reference: "ENV - 12344",
    appointment_type: "environmental",
    partner_type: "enterprise",
    create_date: new Date(),
    partner_id: { firstname: "Nayah", lastname: "Tantoh", id: 1 },
    start_datetime: timeslots[15].start_datetime,
    end_datetime: timeslots[15].end_datetime,
    report_generation_date: buildDate(24, 8, 15),
    state_full: "waiting_lns",
    misa_approval: "no",
    misa_approval_date: new Date(),
    document_count: 2,
    documents: [
      {
        date: timeslots[15].start_datetime,
        s3_url: "https://localhost/technician-report.pdf",
        document_type: "Technician report",
        document_name: "Environmental report 001",
      },
      {
        date: buildDate(24, 8, 15),
        s3_url: "https://localhost/analysis-report.pdf",
        document_type: "GLIMS report",
        document_name: "Analysis report 001",
      },
    ],
    enterprise_name: "ARHS Spikeseed",
  },
  {
    id: 4,
    reference: "TOX - 01234",
    appointment_type: "toxicology",
    create_date: new Date(),
    partner_id: { firstname: "Cameron", lastname: "Rogers", id: 1 },
    start_datetime: timeslots[18].start_datetime,
    end_datetime: timeslots[18].end_datetime,
    state_full: "under_approval",
    document_count: 2,
    documents: [
      {
        date: new Date(),
        s3_url: "https://localhost/technician-report1.pdf",
        document_type: "Technician report",
        document_name: "Toxicological report 001",
      },
      {
        date: new Date(),
        s3_url: "https://localhost/technician-report2.pdf",
        document_type: "Technician report",
        document_name: "Toxicological report 002",
      },
    ],
  },
  {
    id: 5,
    reference: "ENV - 12345",
    appointment_type: "environmental",
    partner_type: "patient",
    create_date: new Date(),
    partner_id: { firstname: "Cameron", lastname: "Rogers", id: 1 },
    start_datetime: timeslots[16].start_datetime,
    end_datetime: timeslots[16].end_datetime,
    state_full: "under_approval",
    //misa_approval: "yes",
    //misa_approval_date: new Date(),
    //report_generation_date: new Date(),
    //misa_report_date: new Date(),
    survey: { adult_number: "2", mold_moisture_presence: "no" },
    document_count: 0,
    documents: [],
  },
  {
    id: 6,
    reference: "ENV - 12346",
    appointment_type: "environmental",
    partner_type: "patient",
    create_date: new Date(),
    partner_id: { firstname: "Jean", lastname: "Dupont", id: 1 },
    start_datetime: timeslots[21].start_datetime,
    end_datetime: timeslots[21].end_datetime,
    state_full: "waiting_lns",
    misa_approval: "yes",
    misa_approval_date: new Date(),
    document_count: 1,
    documents: [
      {
        date: timeslots[21].start_datetime,
        s3_url: "https://localhost/technician-report.pdf",
        document_type: "Technician report",
        document_name: "Environmental report 001",
      },
    ],
  },
  {
    id: 7,
    reference: "ENV - 12347",
    appointment_type: "environmental",
    partner_type: "patient",
    create_date: new Date(),
    partner_id: { firstname: "Alphonse", lastname: "Dubois", id: 1 },
    start_datetime: timeslots[22].start_datetime,
    end_datetime: timeslots[22].end_datetime,
    state_full: "due",
    misa_approval: "yes",
    misa_approval_date: new Date(),
    report_generation_date: new Date(),
    misa_report_date: new Date(),
    document_count: 2,
    documents: [
      {
        date: timeslots[23].start_datetime,
        s3_url: "https://localhost/technician-report.pdf",
        document_type: "Technician report",
        document_name: "Environmental report 001",
      },
      {
        date: timeslots[22].start_datetime,
        s3_url: "https://localhost/analysis-report.pdf",
        document_type: "GLIMS report",
        document_name: "Analysis report 001",
      },
    ],
  },
  {
    id: 8,
    reference: "ALG - 12345",
    appointment_type: "allergology",
    create_date: new Date(),
    partner_id: { firstname: "Cameron", lastname: "Rogers", id: 1 },
    start_datetime: timeslots[23].start_datetime,
    end_datetime: timeslots[23].end_datetime,
    state_full: "canceled",
    document_count: 1,
    documents: [
      {
        date: new Date(),
        s3_url: "https://localhost/technician-report.pdf",
        document_type: "Technician report",
        document_name: "Allergological report 001",
      },
    ],
    survey: {
      child_birthdate: "01-03-2010",
      child_consult: "yes",
      child_name: "Child Rogers",
      child_number: "123456",
      last_reaction_date: timeslots[22].start_datetime,
      lymphocytic_test: "no",
      medicine_number: 2,
    },
  },
];

const appointments: Appointment[] = appointmentDetails.map((details) => ({
  ...details,
}));

export const patientAppointments = appointments.filter(
  (appointment) => appointment?.partner_id?.firstname === "Cameron"
);

export const misaAppointments = appointments.filter(
  (appointment) => appointment.appointment_type === "environmental"
);
