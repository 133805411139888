import endpoints from "../endpoints";
import ApiService from "./ApiService";

export type UpdateProfileLanguageRequestParams = {
  lang: string;
};

export async function updateProfileLanguage({
  lang,
}: UpdateProfileLanguageRequestParams): Promise<void> {
  const res = await ApiService.put(endpoints.updateProfileLanguage(), {
    lang,
  });
  return res.data;
}
