import clsx from "clsx";
import { ReactNode } from "react";

type SubTitleProps = {
  children: ReactNode;
  className?: string;
};
export default function SubTitle({ children, className }: SubTitleProps) {
  return <h2 className={clsx(`font-bold uppercase`, className)}>{children}</h2>;
}
