import get from "lodash/get";
import React, { ReactElement, ReactNode } from "react";
import TextField from "./TextField";

// We will use specific types to handle forms date - this component will be refactored accordingly
// This component will most probably be removed
type InputWithAnswerProps = {
  answer: string;
  answers?: any;
  empty?: string;
  format?: (value: string) => string;
  label: string;
  horizontal?: boolean;
  children: ReactNode | ((props: any) => ReactElement);
  [key: string]: any;
};

export default React.memo(function InputWithAnswer({
  answer,
  answers,
  empty = "-",
  format,
  horizontal,
  label,
  children,
  ...otherProps
}: InputWithAnswerProps) {
  if (answers) {
    let display;

    if ("options" in otherProps) {
      display = otherProps.options.find(
        (o: any) => o.value === get(answers, answer)
      )?.label;
    }

    if (!display) {
      display = get(answers, answer) || empty;
    }

    if (format && display && display !== empty) {
      display = format ? format(display) : display;
    }

    return (
      <TextField
        name=""
        label={label}
        answer={display}
        horizontal={horizontal}
      />
    );
  }

  return (
    <>
      {typeof children === "function"
        ? children({ label, ...otherProps })
        : React.cloneElement(children as React.ReactElement<any>, { label })}
    </>
  );
});
