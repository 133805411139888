import React, { ReactNode } from "react";

export interface CheckBoxFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: ReactNode;
}

export default React.forwardRef<HTMLInputElement, CheckBoxFieldProps>(
  function CheckBoxField({ label, ...props }, ref) {
    return (
      <label className="flex items-center space-x-3 cursor-pointer ">
        <input
          type="checkbox"
          className="rounded border-lns-gray-border"
          ref={ref}
          {...props}
        />
        <span className="inline-flex">{label}</span>
      </label>
    );
  }
);
