import { APPOINTMENT_TYPES } from "../config";
import { AppointmentDetails, DocumentType } from "../models/appointment";

export function getDocumentsByType(
  appointment: AppointmentDetails,
  type: DocumentType
) {
  if (!appointment?.documents) {
    return [];
  }

  return appointment.documents.filter((doc) => doc.document_type === type);
}

export function isAppointmentTypeEnabled(type: string) {
  return APPOINTMENT_TYPES && APPOINTMENT_TYPES.includes(type) ? true : false;
}
