import { useTranslation } from "react-i18next";
import { useWizard } from "../../../components/Wizard/Wizard";
import { capitalizeFirstLetter } from "../../../utils/generic";
import ConfirmationStep, {
  ConfirmationStepAddToCalendar,
  ConfirmationStepExtra,
  ConfirmationStepJoinUs,
  ConfirmationStepSummary,
} from "../ConfirmationStep";

export default function ConfirmationToxicologicalStep() {
  const { t } = useTranslation();
  const {
    values: { appointment_type, timeslot },
  } = useWizard();
  const title = capitalizeFirstLetter(appointment_type);

  return (
    <ConfirmationStep
      title={t("pages.booking.shared.steps.confirmation.title", {
        appointment_type: title,
      })}
    >
      <ConfirmationStepSummary
        endDatetime={timeslot.end_datetime}
        instructionKey="pages.booking.toxicological.steps.confirmation.instructions"
        startDatetime={timeslot.start_datetime}
        title={t("pages.booking.toxicological.steps.confirmation.title")}
      />
      <ConfirmationStepExtra>
        <ConfirmationStepJoinUs />
        <ConfirmationStepAddToCalendar
          endDatetime={timeslot.end_datetime}
          startDatetime={timeslot.start_datetime}
          subject={t("pages.booking.toxicological.title")}
        />
      </ConfirmationStepExtra>
    </ConfirmationStep>
  );
}
