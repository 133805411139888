import { Link } from "react-router-dom";
import { useApp } from "../../contexts/AppContext";
import LanguageDropdown from "../Profile/LanguageDropdown";
import NotificationDropdown from "../Profile/NotificationDropdown";
import ProfileDropdown from "../Profile/ProfileDropdown";
import { ReactComponent as Logo } from "./Logo.svg";

export default function Header() {
  const { isMisa } = useApp();

  return (
    <header className="py-4">
      <div className="flex">
        <div className="flex-1">
          <Link to="/">
            <Logo />
          </Link>
        </div>

        <div className="flex flex-row items-center justify-around divide-x divide-lns-gray-border">
          <div className="flex flex-row items-center">
            {isMisa && <NotificationDropdown />}
            <ProfileDropdown />
          </div>
          <LanguageDropdown />
        </div>
      </div>
    </header>
  );
}
