import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../../../components/Button/Button";
import Container from "../../../components/Layout/Container";
import PageTitle from "../../../components/Typography/PageTitle";
import { useWizard } from "../../../components/Wizard/Wizard";
import SurveyCompanyEnvironmental from "./SurveyCompanyEnvironmental";
import SurveyPrivateEnvironmental from "./SurveyPrivateEnvironmental";

export default function GetInfoEnvironmentalStep() {
  const { t } = useTranslation();
  const { goToNextStep, prevStep, subscriber } = useWizard();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const queryString = subscriber ? `?sub=${subscriber}` : "";
  const prevPath = `${prevStep?.path}${queryString}`;

  useEffect(() => {
    // ghost field
    register("appointment.partner_type", { required: true });
  }, [register]);

  useEffect(() => {
    setValue("appointment.partner_type", subscriber);
  }, [subscriber, setValue]);

  return (
    <form onSubmit={handleSubmit(goToNextStep)} noValidate>
      <Container padded>
        <PageTitle>
          {t("pages.booking.environmental.steps.survey.title")}
        </PageTitle>

        <p className="mb-8">
          {t("pages.booking.environmental.steps.survey.instructions.0")}
          <br />
          <strong>
            {t("pages.booking.environmental.steps.survey.instructions.1")}
          </strong>
        </p>

        {subscriber === "enterprise" ? (
          <SurveyCompanyEnvironmental errors={errors} register={register} />
        ) : (
          <SurveyPrivateEnvironmental
            control={control}
            errors={errors}
            register={register}
          />
        )}

        <div className="flex my-8 gap-x-6">
          <Button variant="outline-primary" as={Link} to={prevPath}>
            {t("components.buttons.cancel")}
          </Button>

          <Button variant="primary" type="submit">
            {t("components.buttons.next")}
          </Button>
        </div>
      </Container>
    </form>
  );
}
