import isEmpty from "lodash/isEmpty";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSetState } from "react-use";
import Button from "../../components/Button/Button";
import Calendar, {
  CalendarEvent,
  VIEW_MODE,
} from "../../components/Calendar/Calendar";
import Empty from "../../components/Empty/Empty";
import Container from "../../components/Layout/Container";
import Loading from "../../components/Loading/Loading";
import Modal from "../../components/Modal/Modal";
import PageTitle from "../../components/Typography/PageTitle";
import { FormValues, useWizard } from "../../components/Wizard/Wizard";
import { useApp } from "../../contexts/AppContext";
import { useTimeslots } from "../../hooks/useTimeslots";
import { createAppointment } from "../../services/AppointmentService";
import { capitalizeFirstLetter, formatISO } from "../../utils/generic";

const NO_EVENT = undefined;
const NO_DATE = undefined;

type CalendarStepState = {
  date: Date;
  selectedEvent?: CalendarEvent;
  showMoreDate?: Date;
  showConfirmation: boolean;
};

function useCalendarState(initialDate: Date) {
  const initialState = {
    date: initialDate,
    selectedEvent: NO_EVENT,
    showMoreDate: NO_DATE,
    showConfirmation: false,
  };

  const [state, setState] = useSetState<CalendarStepState>(initialState);

  return {
    state,
    resetState: () => setState(initialState),
    setState,
    setDate: (date: Date) => setState({ date }),
    setShowMoreDate: (showMoreDate: Date) =>
      setState({ showMoreDate, selectedEvent: NO_EVENT }),
  };
}

export default function CalendarStep() {
  const { t } = useTranslation();
  const { showError, hideError } = useApp();
  const {
    goToNextStep,
    prevStep,
    subscriber,
    values: { appointment_type },
    values,
  } = useWizard();
  const { register, handleSubmit, formState, setValue } = useForm({
    mode: "onChange",
  });

  const {
    state: { selectedEvent, date, showMoreDate, showConfirmation },
    setDate,
    setShowMoreDate,
    setState,
    resetState,
  } = useCalendarState(new Date());

  let params = undefined;
  if (appointment_type === "allergology") {
    params = {
      is_lymphocytic:
        values.appointment?.survey?.lymphocytic_test === "yes" ? true : false,
      last_reaction_date: values.appointment?.survey?.last_reaction_date,
    };
  }

  const { isLoading, data, error } = useTimeslots(appointment_type, params);

  const timeslots = useMemo(() => {
    if (!data) return;
    return data.sort(function (a, b) {
      return a.start_datetime < b.start_datetime
        ? -1
        : a.start_datetime > b.start_datetime
          ? 1
          : 0;
    });
  }, [data]);

  const [submitting, setSubmitting] = useState(false);
  const hasTimeslots = !isEmpty(timeslots);
  const hasSelectedEvent = selectedEvent !== undefined;
  const minDate = new Date();
  const queryString = subscriber ? `?sub=${subscriber}` : "";
  const prevPath = `${prevStep?.path}${queryString}`;

  function handleEventClick(event: CalendarEvent) {
    if (showMoreDate === NO_DATE) {
      setState({ selectedEvent: event, showConfirmation: true });
    } else {
      setState({ selectedEvent: event });
    }
  }

  function handleBookAppointmentClick() {
    setState({
      showMoreDate: NO_DATE,
      showConfirmation: true,
    });
  }

  async function createAppointmentAndNext(stepValues: FormValues) {
    setSubmitting(true);

    try {
      await createAppointment({
        appointment: {
          ...values.appointment,
          timeslot_id: stepValues.timeslot.id,
        },
        appointment_type,
      });

      goToNextStep(stepValues);
    } catch (exception: any) {
      showError(exception);
      resetState();
    } finally {
      setSubmitting(false);
    }
  }

  useEffect(() => {
    // ghost field
    register("timeslot", { required: true });
  }, [register]);

  useEffect(() => {
    setValue("timeslot", selectedEvent, { shouldValidate: true });
  }, [showConfirmation, selectedEvent, setValue]);

  useEffect(() => {
    error ? showError(error) : hideError();
  }, [error, showError, hideError]);

  if (isLoading) {
    return <Loading message={t("pages.booking.shared.loadings.timeslots")} />;
  }

  return (
    <Container padded>
      <PageTitle>
        {t("pages.booking.shared.steps.calendar.title", {
          appointment_type: capitalizeFirstLetter(appointment_type),
        })}
      </PageTitle>

      <div className="flex flex-col mb-4 lg:flex-row gap-y-3 lg:gap-x-3 lg:gap-y-0">
        <div className="flex-1">
          <p
            dangerouslySetInnerHTML={{
              __html: t("pages.booking.shared.steps.calendar.instructions"),
            }}
          ></p>
        </div>

        <div className="flex-1 lg:flex-initial">
          <div className="flex flex-1 gap-3">
            <Button
              variant="outline-primary"
              className="w-full lg:w-auto"
              as={Link}
              to={prevPath}
            >
              {t("components.buttons.back")}
            </Button>
          </div>
        </div>
      </div>

      {!hasTimeslots && (
        <Empty
          subtitle={t(
            "pages.booking.shared.steps.calendar.no_timeslots.subtitle"
          )}
          title={t("pages.booking.shared.steps.calendar.no_timeslots.title")}
        />
      )}

      {hasTimeslots && (
        <Calendar
          date={date}
          minDate={minDate}
          events={timeslots}
          onDateChange={setDate}
          onEventClick={handleEventClick}
          onShowMoreClick={setShowMoreDate}
          selectedEvent={selectedEvent}
        />
      )}

      <Modal open={showConfirmation} size="lg:max-w-md">
        <form onSubmit={handleSubmit(createAppointmentAndNext)}>
          {selectedEvent && (
            <>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("modals.booking_confirmation.text", {
                    date: formatISO(selectedEvent.start_datetime, "d/MM/yyyy"),
                    from: formatISO(selectedEvent.start_datetime, "H:mm"),
                    to: formatISO(selectedEvent.end_datetime, "H:mm"),
                  }),
                }}
              ></p>

              <div className="flex flex-col mt-6 gap-y-3">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!formState.isValid}
                  loading={submitting}
                  className="w-full"
                >
                  {t("components.buttons.confirm")}
                </Button>

                <Button
                  variant="outline-primary"
                  onClick={resetState}
                  className="w-full"
                  disabled={submitting}
                >
                  {t("components.buttons.change_schedule")}
                </Button>
              </div>
            </>
          )}
        </form>
      </Modal>

      <Modal open={showMoreDate !== NO_EVENT} size="lg:max-w-md">
        <Calendar
          date={showMoreDate}
          minDate={minDate}
          events={timeslots}
          onDateChange={setShowMoreDate}
          onEventClick={handleEventClick}
          selectedEvent={selectedEvent}
          viewMode={VIEW_MODE.DAY}
        />

        <div className="flex flex-col mt-6 gap-y-3">
          {hasSelectedEvent && (
            <Button
              variant="primary"
              onClick={handleBookAppointmentClick}
              className="w-full"
              data-testid="ModalBookAppointment"
            >
              {t("components.buttons.book_appointment")}
            </Button>
          )}

          <Button
            variant="outline-primary"
            onClick={resetState}
            className="w-full"
          >
            {t("components.buttons.back")}
          </Button>
        </div>
      </Modal>
    </Container>
  );
}
