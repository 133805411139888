import clsx from "clsx";
import { ReactNode } from "react";
import { AppointmentState } from "../../models/appointment";

type BadgeProps = {
  children: ReactNode;
  state?: AppointmentState;
};

export default function Badge({ children, state }: BadgeProps) {
  const dotColor = clsx({
    "bg-lns-badge-planned":
      state === "planned" ||
      state === "waiting_misa" ||
      state === "waiting_lns",
    "bg-lns-badge-due": state === "due" || state === "done",
    "bg-lns-badge-under_approval": state === "under_approval",
    "bg-lns-badge-cancelled": state === "canceled",
  });

  return (
    <div className="px-0.5 py-1">
      <div className="flex flex-row items-center gap-2 px-3 py-2 text-sm font-bold rounded-full shadow w-min bg-lns-white">
        <span className={`w-2.5 h-2.5 rounded-full ${dotColor}`}></span>
        {children}
      </div>
    </div>
  );
}
