//import { CheckLg } from "react-bootstrap-icons";
//import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
//import Button from "../../components/Button/Button";
import DocumentsTable from "../../components/Documents/DocumentsTable";
import DisclosurePanel from "../../components/Panel/DisclosurePanel";
//import { useApp } from "../../contexts/AppContext";
//import { useAppointmentValidation } from "../../hooks/useAppointments";
import { AppointmentDetails, Document } from "../../models/appointment";

type MedicalAnalysisPanelProps = {
  appointment: AppointmentDetails;
  glimsReports: Document[];
  misaReports: Document[];
};

export default function MedicalAnalysisPanel({
  appointment,
  glimsReports,
  misaReports,
}: MedicalAnalysisPanelProps) {
  //const { showError } = useApp();
  const { t } = useTranslation();

  /*
  const { validateAppointment } = useAppointmentValidation();

  const { handleSubmit, setValue } = useForm({
    defaultValues: {
      upload: {},
    },
    mode: "onChange",
  });

  const dued =
    appointment.state_full === "due" || appointment.state_full === "done";

  async function validate(values: any) {
    try {
      await validateAppointment({ id: appointment.id, ...values });
    } catch (exception: any) {
      showError(exception);
    }
  }
  */

  return (
    <DisclosurePanel title={t("pages.appointment_details.medical_analysis")}>
      <p className="mb-6">
        {t("pages.appointment_details.medical_analysis_description")}
      </p>
      <DocumentsTable documents={glimsReports} appointment={appointment} />
      {/*
      <hr className="mt-6 mb-8 border-lns-gray-border" />
      <div className="mb-6 font-bold uppercase">
        {t("pages.appointment_details.modified_by_you")}
      </div>
      <p className="mb-6">
        {t("pages.appointment_details.modified_by_you_description")}
      </p>
      <DocumentsTable
        appointment={appointment}
        documents={misaReports}
        onUpload={(e: any) => {
          setValue("upload", e.target.files[0]);
        }}
        uploadDisabled={appointment.state_full !== "waiting_misa"}
      />
      <hr className="mt-6 mb-8 border-lns-gray-border" />
      <div className="flex items-center justify-end mt-6 gap-x-6">
        {t("pages.appointment_details.approve_action_warning")}
        <Button
          disabled={dued || appointment.state_full !== "waiting_misa"}
          icon={<CheckLg />}
          variant="success"
          onClick={handleSubmit(validate)}
        >
          {t(
            dued ? "components.buttons.approved" : "components.buttons.approve"
          )}
        </Button>
      </div>
      */}
    </DisclosurePanel>
  );
}
