import clsx from "clsx";
import { Fragment, ReactNode } from "react";
import { Calendar, Clock } from "react-bootstrap-icons";
import { Trans, useTranslation } from "react-i18next";
import { Appointment } from "../../models/appointment";
import { formatISO } from "../../utils/generic";
import Badge from "../Badge/Badge";
import Panel from "../Panel/Panel";

type AppointmentDetailsTableProps = {
  appointments?: Appointment[];
};

type TableConfiguration = {
  id: string;
  label: string;
  className?: string;
  labelClassName?: string;
  render?: (row: Appointment) => ReactNode;
};

const configuration: TableConfiguration[] = [
  {
    id: "reference",
    label: "components.appointment_table.reference",
  },
  {
    id: "appointment_type",
    label: "components.appointment_table.appointment_type",
    render: ({ appointment_type }) => (
      <Trans i18nKey={`models.appointment.analyzes.${appointment_type}`} />
    ),
  },
  {
    id: "date_time",
    label: "components.appointment_table.date_time",
    labelClassName: "self-start",
    render: ({ start_datetime }) => (
      <div className="flex flex-col gap-2 lg:gap-3 lg:flex-row">
        <div className="flex flex-row items-center gap-2">
          <div>
            <Calendar />
          </div>
          <span>{formatISO(start_datetime, "d-MM-yyyy")}</span>
        </div>
        <div className="flex flex-row items-center gap-2">
          <div>
            <Clock />
          </div>
          <span>{formatISO(start_datetime, "HH:mm")}</span>
        </div>
      </div>
    ),
  },
  {
    id: "status",
    label: "components.appointment_table.status",
    render: ({ state_full }) => (
      <Badge state={state_full}>
        <Trans i18nKey={`models.appointment.state.${state_full}`} />
      </Badge>
    ),
  },
];

export default function AppointmentDetailsTable({
  appointments = [],
}: AppointmentDetailsTableProps) {
  const { t } = useTranslation();
  return (
    <div className="mt-4">
      <div className="flex flex-col">
        <div className="flex-col justify-between hidden gap-4 gap-x-0 lg:items-center lg:grid lg:grid-cols-4">
          {configuration.map((config) => (
            <div
              key={config.id}
              className={clsx(
                "flex flex-row items-center gap-2",
                config.className
              )}
            >
              <div className="truncate text-lns-gray-text">
                {t(config.label)}
              </div>
            </div>
          ))}
        </div>

        {appointments.map((app) => (
          <Panel key={app.id} className="px-0 py-0 border-none lg:px-0 lg:py-0">
            <div className="grid items-center grid-cols-2 gap-4 gap-x-0 lg:grid-cols-4">
              {configuration.map((config) => (
                <Fragment key={config.id}>
                  <span
                    className={clsx(
                      "lg:hidden text-lns-gray-text",
                      config.labelClassName
                    )}
                  >
                    {t(config.label)}
                  </span>
                  <span className={clsx("truncate", config.className)}>
                    {config.render?.(app) ||
                      app[config.id as keyof Appointment]?.toString()}
                  </span>
                </Fragment>
              ))}
            </div>
          </Panel>
        ))}
      </div>
    </div>
  );
}
