import { useTranslation } from "react-i18next";
import { useWizard } from "../../../components/Wizard/Wizard";
import WelcomeStep from "../WelcomeStep";

export default function WelcomeEnvironmentalStep() {
  const { t } = useTranslation();
  const { goToNextStep, subscriber } = useWizard();

  return (
    <WelcomeStep
      confirmation={t("pages.booking.environmental.steps.welcome.form.confirm")}
      instructionKey={
        subscriber === "enterprise"
          ? "pages.booking.environmental.steps.welcome.company.instructions"
          : "pages.booking.environmental.steps.welcome.private.instructions"
      }
      onSubmit={goToNextStep}
      title={t("pages.booking.environmental.steps.welcome.title")}
    />
  );
}
