import { useMemo } from "react";
import { useApp } from "../contexts/AppContext";
import { Appointment } from "../models/appointment";
import { Notification } from "../models/notification";
import { useAppointments } from "./useAppointments";

export function useNotifications() {
  const { isMisa } = useApp();
  const { data } = useAppointments(isMisa);

  const notifications = useMemo(
    () => computeNotificationsFromAppointments(data),
    [data]
  );

  return {
    notifications,
  };
}

// TODO - rules need to be added here
export function computeNotificationsFromAppointments(
  appointments: Appointment[] = []
): Notification[] {
  let notifications: Notification[] = [];

  appointments.forEach((appointment) => {
    // TODO - use constants/enum for status
    if (appointment.state_full === "under_approval") {
      notifications.push({
        appointmentId: appointment.id,
        appointment_type: appointment.appointment_type,
        name: `${appointment.partner_id?.firstname} ${appointment.partner_id?.lastname}`,
        message: "", // Will be used for label key
      });
    }
  });

  return notifications;
}
