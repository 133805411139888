import { Disclosure } from "@headlessui/react";
import clsx from "clsx";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useBreakpoints from "../../hooks/useBreakpoints";
import { isAppointmentTypeEnabled } from "../../utils/appointment";
import { capitalizeFirstLetter } from "../../utils/generic";
import Button from "../Button/Button";
import Card from "../Card/Card";
import { ReactComponent as CompanyIcon } from "../icons/company_icon.svg";
import { ReactComponent as EnvironmentalIcon } from "../icons/envirenment_icon.svg";
import { ReactComponent as PrivateIcon } from "../icons/private_icon.svg";
import { ReactComponent as ToxicologicalIcon } from "../icons/toxicoligical_icon.svg";
import Modal from "../Modal/Modal";

const types = [
  {
    id: "toxicology",
    testid: "testtoxicological",
    icon: ToxicologicalIcon,
    title: "modals.appointment_types_selection.cards.toxicological.title",
    description:
      "modals.appointment_types_selection.cards.toxicological.description",
    to: "/booking/toxicology",
  },
  {
    id: "environmental",
    testid: "testenvironmental",
    icon: EnvironmentalIcon,
    title: "modals.appointment_types_selection.cards.environmental.title",
    description:
      "modals.appointment_types_selection.cards.environmental.description",
  },
  {
    id: "biological",
    testid: "testbiological",
    icon: CompanyIcon,
    title: "modals.appointment_types_selection.cards.biological.title",
    description:
      "modals.appointment_types_selection.cards.biological.description",
    to: "/booking/biological",
  },
  {
    id: "allergology",
    testid: "testallergology",
    icon: CompanyIcon,
    title: "modals.appointment_types_selection.cards.allergology.title",
    description:
      "modals.appointment_types_selection.cards.allergology.description",
    to: "/booking/allergology",
  },
];

const environmentalSubtypes = [
  {
    id: "testpatient",
    icon: PrivateIcon,
    title:
      "modals.appointment_types_selection.cards.environmental.patient.title",
    description:
      "modals.appointment_types_selection.cards.environmental.patient.description",
    to: "/booking/environmental?sub=patient",
  },
  {
    id: "testenterprise",
    icon: CompanyIcon,
    title:
      "modals.appointment_types_selection.cards.environmental.enterprise.title",
    description:
      "modals.appointment_types_selection.cards.environmental.enterprise.description",
    to: "/booking/environmental?sub=enterprise",
  },
];

const cols: { [key: string]: string } = {
  "0": "",
  "1": "",
  "2": "lg:grid-cols-2",
  "3": "lg:grid-cols-3",
  "4": "lg:grid-cols-4",
};

const sizes: { [key: string]: string } = {
  1: "lg:max-w-sm",
  2: "lg:max-w-2xl",
  3: "lg:max-w-4xl",
};

type ModalTestSelectionProps = {
  open: boolean;
  onClose: () => void;
};

export default function ModalTestSelection({
  open,
  onClose,
}: ModalTestSelectionProps) {
  const { t } = useTranslation();
  const [showTestEnvironmental, setShowTestEnvironmental] = useState(false);
  const [isMobile] = useBreakpoints();

  function handleCancelClick() {
    setShowTestEnvironmental(false);
    onClose();
  }

  function handleShowTestEnvironmental() {
    setShowTestEnvironmental(true);
  }

  const typesEnabled = useMemo(() => {
    return types.filter((type) => isAppointmentTypeEnabled(type.id));
  }, []);

  return (
    <>
      <Modal
        open={open && !showTestEnvironmental}
        header={t("modals.appointment_types_selection.title")}
        size={sizes[typesEnabled.length] || "lg:max-w-6xl"}
      >
        <div
          className={clsx(
            "grid grid-cols-1 gap-6 lg:mt-4",
            cols[typesEnabled.length]
          )}
        >
          {typesEnabled.map((type) => (
            <Card key={type.id}>
              <type.icon className="m-3" />
              <Card.Title>{t(type.title)}</Card.Title>
              <Card.Body>
                <Disclosure defaultOpen={!isMobile}>
                  {({ open }) => (
                    <>
                      {!open && (
                        <Disclosure.Button className="underline text-lns-primary lg:hidden">
                          {t("components.buttons.more_information")}
                        </Disclosure.Button>
                      )}
                      <Disclosure.Panel>
                        <Trans i18nKey={type.description} parent="p" />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </Card.Body>
              <Card.Action>
                {type.id === "environmental" ? (
                  <Button
                    variant="primary"
                    onClick={handleShowTestEnvironmental}
                    data-testid={type.testid}
                  >
                    {t("components.buttons.book_appointment")}
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    as={Link}
                    to={type.to}
                    data-testid={type.testid}
                  >
                    {t("components.buttons.book_appointment")}
                  </Button>
                )}
              </Card.Action>
            </Card>
          ))}
        </div>

        <div className="flex justify-end mt-6">
          <Button
            variant="outline-primary"
            onClick={handleCancelClick}
            data-testid="cancel"
          >
            {t("components.buttons.cancel")}
          </Button>
        </div>
      </Modal>

      <Modal
        open={open && showTestEnvironmental}
        header={capitalizeFirstLetter(
          t("modals.appointment_types_selection.cards.environmental.title")
        )}
        size="lg:max-w-4xl"
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:mt-4">
          {environmentalSubtypes.map((subtype) => (
            <Card key={subtype.id}>
              <subtype.icon className="m-3" />
              <Card.Title>{t(subtype.title)}</Card.Title>
              <Card.Body>
                <Disclosure defaultOpen={!isMobile}>
                  {({ open }) => (
                    <>
                      {!open && (
                        <Disclosure.Button className="underline text-lns-primary lg:hidden">
                          {t("components.buttons.more_information")}
                        </Disclosure.Button>
                      )}
                      <Disclosure.Panel>
                        <Trans i18nKey={subtype.description} parent="p" />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </Card.Body>
              <Card.Action>
                <Button
                  variant="primary"
                  as={Link}
                  to={subtype.to}
                  data-testid={subtype.id}
                >
                  {t("components.buttons.book_appointment")}
                </Button>
              </Card.Action>
            </Card>
          ))}
        </div>

        <div className="flex justify-end mt-6">
          <Button variant="outline-primary" onClick={handleCancelClick}>
            {t("components.buttons.cancel")}
          </Button>
        </div>
      </Modal>
    </>
  );
}
