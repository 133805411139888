import { FileEarmarkArrowDown } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useApp } from "../../contexts/AppContext";
import { AppointmentDetails } from "../../models/appointment";
import { downloadAppointmentDocument } from "../../services/AppointmentService";
import Button from "../Button/Button";

type ReportListProps = {
  appointment: AppointmentDetails;
};

export default function ReportList({ appointment }: ReportListProps) {
  const { isMisa } = useApp();
  const { t } = useTranslation();

  if (!appointment?.documents || appointment.documents.length === 0) {
    return <p>{t("pages.appointment_details.no_diagnostic_reports")}</p>;
  }

  return (
    <div className="flex flex-col gap-y-3">
      {appointment.documents.map((report) => (
        <div
          key={report.s3_url}
          className="flex flex-col lg:items-center lg:flex-row gap-y-3"
        >
          <p className="flex-auto">{report.document_name}</p>
          <Button
            onClick={() =>
              downloadAppointmentDocument({
                s3_url: report.s3_url,
                appointmentId: appointment.id,
                appointment_type: appointment.appointment_type,
                misa: !!isMisa,
              })
            }
            className="w-full lg:flex-none lg:w-min"
            icon={<FileEarmarkArrowDown />}
          >
            {t("components.buttons.download")}
          </Button>
        </div>
      ))}
    </div>
  );
}
