import { ReactNode } from "react";

type PanelProps = {
  children?: ReactNode;
  className?: string;
};

export default function Panel({ children, className }: PanelProps) {
  return (
    <div
      className={`p-4 lg:p-6 border rounded-lg border-lns-gray-border ${className}`}
    >
      {children}
    </div>
  );
}
