import {
  differenceInCalendarDays,
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfMonth,
  lastDayOfWeek,
  parseISO,
  startOfMonth,
} from "date-fns";
import { CalendarEvent } from "../components/Calendar/Calendar";

const WEEK_STARTS_ON = 1; // 0 Sunday // 1 Monday // 2 Tuesday...

/**
 * Returns an array of weeks containing the days of each week for the given date
 */
export function getWeeksInMonth(date: Date) {
  // get an array containing all the weeks of the month
  const weeksOfInterval = eachWeekOfInterval(
    {
      start: startOfMonth(date),
      end: endOfMonth(date),
    },
    { weekStartsOn: WEEK_STARTS_ON }
  );

  // for each week of the month, let's fill the array with the days in the week
  const calendarDays = weeksOfInterval.map((week) =>
    eachDayOfInterval({
      start: week,
      end: lastDayOfWeek(week, { weekStartsOn: WEEK_STARTS_ON }),
    })
  );

  return calendarDays;
}

//https://github.com/date-fns/date-fns/blob/master/docs/upgradeGuide.md#string-arguments
export function fixDateFNSWarningMsg(datetime: Date | string) {
  return typeof datetime === "string" ? datetime : datetime.toISOString();
}

export function findEventsForDay(day: Date, events: CalendarEvent[]) {
  return events.filter(
    (event) =>
      differenceInCalendarDays(
        parseISO(fixDateFNSWarningMsg(event.start_datetime)),
        day
      ) === 0
  );
}
