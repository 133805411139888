import clsx from "clsx";
import { Fragment, ReactNode } from "react";
import {
  Calendar,
  FileEarmarkArrowDown,
  FileEarmarkArrowUp,
} from "react-bootstrap-icons";
import { Trans, useTranslation } from "react-i18next";
import { Appointment, Document } from "../../models/appointment";
import { downloadMISADocument } from "../../services/AppointmentService";
import { formatISO } from "../../utils/generic";
import Button from "../Button/Button";
import Panel from "../Panel/Panel";

// TODO - DocumentsTable component will be refactored along with ReportList and InvoiceList
// This refactoring can be done while working on download/upload features

type DocumentsRowProps = {
  appointment: Appointment;
  document: Document;
  onUpload?: (file: any) => void;
  uploadDisabled?: boolean;
};

type DocumentsTableProps = {
  appointment: Appointment;
  documents?: Document[];
  onUpload?: (file: any) => void;
  uploadDisabled?: boolean;
};

type TableConfiguration = {
  id: string;
  label: string;
  className?: string;
  labelClassName?: string;
  render?: (
    row: Document,
    {
      appointment,
      onUpload,
      uploadDisabled,
    }: {
      appointment: Appointment;
      onUpload?: (file: any) => void;
      uploadDisabled?: boolean;
    }
  ) => ReactNode;
};

const configuration: TableConfiguration[] = [
  {
    id: "name",
    label: "components.documents_table.name",
    render: ({ document_name }) => (
      <span>
        {document_name ? (
          document_name
        ) : (
          <Trans i18nKey={"components.documents_table.no_documents"} />
        )}
      </span>
    ),
  },
  {
    id: "upload_date",
    label: "components.documents_table.date",
    render: ({ date }) => (
      <div className="flex flex-row items-center gap-2">
        <Calendar />
        <span>{date ? formatISO(date, "d-MM-yyyy") : " - - "}</span>
      </div>
    ),
  },
  {
    id: "action",
    label: "components.documents_table.action",
    render: ({ s3_url }, { appointment, onUpload, uploadDisabled }) => (
      <div className="flex flex-row items-center gap-2">
        {onUpload && (
          <div className="relative">
            <Button
              className="mt-1 pointer-events-auto"
              disabled={uploadDisabled}
              icon={<FileEarmarkArrowUp />}
              variant="outline-primary"
            >
              <Trans i18nKey="components.buttons.upload" />
            </Button>
            <input
              type="file"
              className="absolute inset-0 w-full h-full opacity-0 -z-1"
              onChange={onUpload}
              disabled={uploadDisabled}
            />
          </div>
        )}

        <Button
          className="w-full mt-1 lg:flex-none lg:w-min"
          disabled={!s3_url}
          icon={<FileEarmarkArrowDown />}
          onClick={() =>
            downloadMISADocument({
              s3_url,
              appointmentId: appointment.id,
              appointment_type: appointment.appointment_type,
              misa: true,
            })
          }
        >
          <Trans i18nKey="components.buttons.download" />
        </Button>
      </div>
    ),
  },
];

function DocumentsRow({
  document,
  appointment,
  onUpload,
  uploadDisabled,
}: DocumentsRowProps) {
  const { t } = useTranslation();
  return (
    <Panel className="px-0 py-0 border-none lg:px-0 lg:py-0">
      <div className="grid items-center grid-cols-2 gap-4 gap-x-0 lg:grid-cols-3">
        {configuration.map((config) => (
          <Fragment key={config.id}>
            <span
              className={clsx(
                "lg:hidden text-lns-gray-text",
                config.labelClassName
              )}
            >
              {t(config.label)}
            </span>
            <span className={clsx("truncate", config.className)}>
              {config.render?.(document, {
                appointment,
                onUpload,
                uploadDisabled,
              }) || document[config.id as keyof Document]?.toString()}
            </span>
          </Fragment>
        ))}
      </div>
    </Panel>
  );
}

export default function DocumentsTable({
  documents = [],
  appointment,
  onUpload,
  uploadDisabled,
}: DocumentsTableProps) {
  const { t } = useTranslation();
  return (
    <div className="mt-4">
      <div className="flex flex-col">
        <div className="flex-col justify-between hidden gap-4 gap-x-0 lg:items-center lg:grid lg:grid-cols-3">
          {configuration.map((config) => (
            <div
              key={config.id}
              className={clsx(
                "flex flex-row items-center gap-2",
                config.className
              )}
            >
              <div className="text-sm truncate text-lns-gray-text">
                {t(config.label)}
              </div>
            </div>
          ))}
        </div>

        {(!documents || documents.length === 0) && (
          <DocumentsRow
            appointment={appointment}
            document={{} as Document}
            onUpload={onUpload}
            uploadDisabled={uploadDisabled}
          />
        )}

        {documents.map((document, idx) => (
          <DocumentsRow
            key={idx}
            appointment={appointment}
            document={document}
            onUpload={onUpload}
            uploadDisabled={uploadDisabled}
          />
        ))}
      </div>
    </div>
  );
}
