import { useEffect } from "react";
import { ExclamationTriangleFill, X } from "react-bootstrap-icons";

type AlertProps = {
  type?: "error" | "info";
  message: string;
  onHide?: () => void;
};

export default function Alert({ type = "error", message, onHide }: AlertProps) {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  }, [message, type]);

  return (
    <div className="flex items-center justify-between p-4 px-8 my-3 font-semibold border rounded border-lns-error text-lns-error bg-lns-light-error">
      <div className="flex items-center">
        <ExclamationTriangleFill className="mr-3" />

        {message}
      </div>

      {onHide && (
        <button onClick={onHide} className="p-3 cursor-pointer">
          <X />
        </button>
      )}
    </div>
  );
}
