import { ReactNode } from "react";
import { ReactComponent as UnderlineTitle } from "./underline_title.svg";

export default function PageTitle({ children }: { children: ReactNode }) {
  return (
    <div className="mb-6">
      <h1 className="leading-9 text-title">{children}</h1>
      <UnderlineTitle className="mt-4 mb-7 d-block" />
    </div>
  );
}
