import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL } from "../config";
import { getToken, isLoggedIn, updateToken } from "./AuthService";

const ApiService = axios.create();

export const configureAxios = () => {
  ApiService.defaults.baseURL = BASE_URL;

  ApiService.interceptors.request.use((config: AxiosRequestConfig) => {
    if (isLoggedIn()) {
      const cb = () => {
        if (!config?.headers) {
          throw new Error(
            `Expected 'config' and 'config.headers' not to be undefined`
          );
        }
        config.headers.Authorization = `Bearer ${getToken()}`;
        return Promise.resolve(config);
      };
      return updateToken(cb) as any;
    }
  });
};

export default ApiService;
