import { useTranslation } from "react-i18next";
import InputWithAnswer from "../../../components/Inputs/InputWithAnswer";
import RadioField from "../../../components/Inputs/RadioField";
import RadioGroupField from "../../../components/Inputs/RadioGroupField";
import TextArea from "../../../components/Inputs/TextArea";
import TextField from "../../../components/Inputs/TextField";

type SurveyBiologicalProps = {
  errors?: { [x: string]: any };
  register?: any;
  answers?: any;
};

export default function SurveyBiological({
  answers,
  errors,
  register = () => {}, // default to empty object for survey results page
}: SurveyBiologicalProps) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col grid-cols-3 gap-8 lg:gap-y-12 lg:grid lg:items-end">
      <InputWithAnswer
        answer="survey.height"
        label={t("pages.booking.biological.steps.survey.form.height")}
        answers={answers}
      >
        <TextField
          {...register("appointment.survey.height", { required: true })}
          error={
            errors?.appointment?.survey?.height && t("components.form.required")
          }
          required
        />
      </InputWithAnswer>

      <InputWithAnswer
        answer="survey.weight"
        label={t("pages.booking.biological.steps.survey.form.weight")}
        answers={answers}
      >
        <TextField
          {...register("appointment.survey.weight", { required: true })}
          error={
            errors?.appointment?.survey?.weight && t("components.form.required")
          }
          required
        />
      </InputWithAnswer>

      <div className="col-start-1">
        <InputWithAnswer
          answer="survey.last_dentist_visit"
          label={t(
            "pages.booking.biological.steps.survey.form.last_dentist_visit"
          )}
          answers={answers}
          options={[
            {
              label: t(
                "pages.booking.biological.steps.survey.form.last_dentist_visit_values.less_1"
              ),
              value: "less_1",
            },
            {
              label: t(
                "pages.booking.biological.steps.survey.form.last_dentist_visit_values.1_to_6"
              ),
              value: "1_to_6",
            },
            {
              label: t(
                "pages.booking.biological.steps.survey.form.last_dentist_visit_values.6_to_12"
              ),
              value: "6_to_12",
            },
            {
              label: t(
                "pages.booking.biological.steps.survey.form.last_dentist_visit_values.more_than_12"
              ),
              value: "more_than_12",
            },
          ]}
        >
          {({ options, label }) => (
            <RadioGroupField
              error={
                errors?.appointment?.survey?.last_dentist_visit &&
                t("components.form.required")
              }
              label={label}
              required
            >
              {options?.map((option: { label: string; value: string }) => (
                <RadioField
                  {...register("appointment.survey.last_dentist_visit", {
                    required: true,
                  })}
                  key={option.label}
                  label={option.label}
                  value={option.value}
                />
              ))}
            </RadioGroupField>
          )}
        </InputWithAnswer>
      </div>

      <InputWithAnswer
        answer="survey.last_amalgam"
        label={t("pages.booking.biological.steps.survey.form.last_amalgam")}
        answers={answers}
        options={[
          {
            label: t(
              "pages.booking.biological.steps.survey.form.last_amalgam_values.less_1"
            ),
            value: "less_1",
          },
          {
            label: t(
              "pages.booking.biological.steps.survey.form.last_amalgam_values.1_to_6"
            ),
            value: "1_to_6",
          },
          {
            label: t(
              "pages.booking.biological.steps.survey.form.last_amalgam_values.6_to_12"
            ),
            value: "6_to_12",
          },
          {
            label: t(
              "pages.booking.biological.steps.survey.form.last_amalgam_values.more_than_12"
            ),
            value: "more_than_12",
          },
        ]}
      >
        {({ options, label }) => (
          <RadioGroupField
            error={
              errors?.appointment?.survey?.last_amalgam &&
              t("components.form.required")
            }
            label={label}
            required
          >
            {options?.map((option: { label: string; value: string }) => (
              <RadioField
                {...register("appointment.survey.last_amalgam", {
                  required: true,
                })}
                key={option.label}
                label={option.label}
                value={option.value}
              />
            ))}
          </RadioGroupField>
        )}
      </InputWithAnswer>

      <InputWithAnswer
        answer="survey.is_smoking"
        label={t("pages.booking.biological.steps.survey.form.is_smoking")}
        answers={answers}
        options={[
          {
            label: t(
              "pages.booking.biological.steps.survey.form.is_smoking_values.no"
            ),
            value: "no",
          },
          {
            label: t(
              "pages.booking.biological.steps.survey.form.is_smoking_values.less_10"
            ),
            value: "less_10",
          },
          {
            label: t(
              "pages.booking.biological.steps.survey.form.is_smoking_values.10_to_20"
            ),
            value: "10_to_20",
          },
          {
            label: t(
              "pages.booking.biological.steps.survey.form.is_smoking_values.more_than_20"
            ),
            value: "more_than_20",
          },
        ]}
      >
        {({ options, label }) => (
          <RadioGroupField
            error={
              errors?.appointment?.survey?.is_smoking &&
              t("components.form.required")
            }
            label={label}
            required
          >
            {options?.map((option: { label: string; value: string }) => (
              <RadioField
                {...register("appointment.survey.is_smoking", {
                  required: true,
                })}
                key={option.label}
                label={option.label}
                value={option.value}
              />
            ))}
          </RadioGroupField>
        )}
      </InputWithAnswer>

      <div className="col-span-3">
        <InputWithAnswer
          answer="survey.reasons"
          label={t("pages.booking.biological.steps.survey.form.reasons")}
          answers={answers}
        >
          <TextArea
            {...register("appointment.survey.reasons", { required: true })}
            error={
              errors?.appointment?.survey?.reasons &&
              t("components.form.required")
            }
            required
            rows="4"
            helper={t("components.form.max_x_characters", { x: "500" })}
          />
        </InputWithAnswer>
      </div>
    </div>
  );
}
