import endpoints from "../endpoints";
import { Timeslot } from "../models/timeslot";
import ApiService from "./ApiService";

export type GetTimeslotRequestParams = {
  appointment_type: string;
  params?: { [key: string]: any };
};

export type GetTimeslotResponse = {
  data: Timeslot[];
  size: number;
};

export async function getTimeslots({
  appointment_type,
  params,
}: GetTimeslotRequestParams): Promise<Timeslot[]> {
  const res = await ApiService.get<GetTimeslotResponse>(
    endpoints.getTimeslots(appointment_type),
    { params }
  );
  return res.data.data;
}
