import clsx from "clsx";
import { useRef, useState } from "react";
import { FileEarmarkArrowUp, X } from "react-bootstrap-icons";

type UploadAreaProps = {
  id: string;
  className?: string;
  label?: string;
  onChange?: (file: any) => void;
};

export default function UploadArea({
  id,
  className,
  label,
  onChange,
}: UploadAreaProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | undefined>(undefined);

  const handleFileUpload = (e: any) => {
    const { files } = e.target;
    if (!files?.length) return;
    setFile(files[0]);
    if (onChange) onChange(files[0]);
  };

  const handleRemoveFile = () => {
    setFile(undefined);
    if (inputRef.current) inputRef.current.value = "";
    if (onChange) onChange(undefined);
  };

  return (
    <div className={clsx("relative space-y-1", className)}>
      {label && (
        <label
          className="flex-shrink-0 text-lns-gray-text"
          htmlFor={id}
          id={`${id}-label`}
        >
          {label}
        </label>
      )}
      <div className="relative border-dashed h-48 rounded-lg border-2 border-lns-gray-border">
        <div
          className={clsx(
            "absolute w-full h-full flex items-center justify-center",
            file && "z-10"
          )}
        >
          <div className="flex flex-col items-center">
            {file ? (
              <div className="flex items-center">
                {file.name}
                <button
                  className="inline-flex items-center justify-center rounded-full w-8 h-8 bg-transparent hover:bg-lns-gray-background ml-1"
                  onClick={handleRemoveFile}
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
            ) : (
              <>
                <FileEarmarkArrowUp className="w-6 h-6 mb-3" />
                <span className="block text-lns-gray-text font-normal">
                  Click here to choose a file
                </span>
              </>
            )}
          </div>
        </div>
        <input
          id={id}
          ref={inputRef}
          onChange={handleFileUpload}
          type="file"
          className="h-full w-full opacity-0 cursor-pointer"
          name=""
        />
      </div>
    </div>
  );
}
