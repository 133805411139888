import fileDownload from "js-file-download";
import endpoints from "../endpoints";
import { Appointment, AppointmentDetails } from "../models/appointment";
import {
  AllergologicalSurvey,
  BiologicalSurvey,
  EnvironmentalSurvey,
} from "../models/survey";
import ApiService from "./ApiService";

export type GetAppointmentsResponse = {
  data: Appointment[];
  size: number;
};

export async function getAppointments(
  misa: boolean = false
): Promise<Appointment[]> {
  const res = await ApiService.get<GetAppointmentsResponse>(
    endpoints.getAppointments(misa)
  );
  return res.data.data;
}

export type GetAppointmentDetailsRequestParams = {
  appointment_type: string;
  appointmentId: string;
  misa: boolean;
};

export type GetAppointmentDetailsResponse = {
  data: AppointmentDetails[];
  size: number;
};

export async function getAppointmentDetails({
  appointment_type,
  appointmentId,
  misa,
}: GetAppointmentDetailsRequestParams): Promise<AppointmentDetails> {
  const res = await ApiService.get<GetAppointmentDetailsResponse>(
    endpoints.getAppointmentDetails(appointmentId, appointment_type, misa)
  );
  return res.data.data[0];
}

export type CreateAppointmentRequestParams = {
  appointment_type: string;
  appointment: {
    partner_type?: string;
    prescription?: File;
    timeslot_id: number;
    survey?: AllergologicalSurvey | BiologicalSurvey | EnvironmentalSurvey;
  };
};

export async function createAppointment({
  appointment_type,
  appointment,
}: CreateAppointmentRequestParams): Promise<boolean> {
  const { partner_type, prescription, timeslot_id, survey, ...rest } =
    appointment;

  const formData = new FormData();
  formData.append(
    "form",
    JSON.stringify({
      partner_type,
      timeslot_id: Number(timeslot_id),
      survey,
      ...rest,
    })
  );

  if (prescription) {
    formData.append("prescription", prescription);
  }

  const res = await ApiService.post(
    endpoints.createAppointment(appointment_type),
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );

  return res.data;
}

export type DeleteAppointmentRequestParams = {
  appointment_type: string;
  appointmentId: number;
};

export async function deleteAppointment({
  appointmentId,
  appointment_type,
}: DeleteAppointmentRequestParams): Promise<boolean> {
  const res = await ApiService.delete(
    endpoints.deleteAppointment(appointmentId, appointment_type)
  );
  return res.data;
}

export type approveMisaEnvironmentalAppointmentRequestParams = {
  id: number;
  misa_approval: string;
  misa_approval_feedback: string;
  upload: File;
};

export async function approveMisaEnvironmentalAppointment({
  id,
  misa_approval,
  misa_approval_feedback,
  upload,
}: approveMisaEnvironmentalAppointmentRequestParams): Promise<void> {
  const formData = new FormData();
  formData.append("misa_approval", misa_approval);
  formData.append("misa_approval_feedback", misa_approval_feedback);
  formData.append("upload", upload);

  const res = await ApiService.put(
    endpoints.approveMisaEnvironmentalAppointment(id),
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );

  return res.data;
}

export type validateMisaEnvironmentalAppointmentRequestParams = {
  id: number;
  upload: File;
};

export async function validateMisaEnvironmentalAppointment({
  id,
  upload,
}: validateMisaEnvironmentalAppointmentRequestParams): Promise<void> {
  const formData = new FormData();
  formData.append("upload", upload);

  const res = await ApiService.put(
    endpoints.validateMisaEnvironmentalAppointment(id),
    formData
  );
  return res.data;
}

export type DownloadAppointmentDocumentRequestParams = {
  appointment_type: string;
  appointmentId: number;
  s3_url: string;
  misa: boolean;
};

export async function downloadAppointmentDocument({
  s3_url,
  appointmentId,
  appointment_type,
  misa = false,
}: DownloadAppointmentDocumentRequestParams) {
  ApiService.get(endpoints.downloadAppointmentDocument(), {
    params: { s3_url, appointment_type, appointmentId, misa },
    responseType: "blob",
  }).then((res) => {
    const splitted = s3_url.split("/");
    const filename = splitted[splitted.length - 1];
    fileDownload(res.data, filename);
  });
}

export type DownloadAppointmentInvoiceRequestParams = {
  filename: string;
  invoiceId: number;
};

export async function downloadAppointmentInvoice({
  filename,
  invoiceId,
}: DownloadAppointmentInvoiceRequestParams) {
  ApiService.get(endpoints.downloadAppointmentInvoice(invoiceId), {
    responseType: "blob",
  }).then((res) => {
    fileDownload(res.data, `${filename}.pdf`);
  });
}

export type DownloadMISADocumentRequestParams = {
  s3_url: string;
  appointment_type: string;
  appointmentId: number;
  misa: boolean;
};

export async function downloadMISADocument({
  s3_url,
  appointmentId,
  appointment_type,
  misa,
}: DownloadMISADocumentRequestParams) {
  ApiService.get(endpoints.downloadMISADocument(), {
    params: { s3_url, appointmentId, appointment_type, misa },
    responseType: "blob",
  }).then((res) => {
    const splitted = s3_url.split("/");
    const filename = splitted[splitted.length - 1];
    fileDownload(res.data, filename);
  });
}
