import clsx from "clsx";
import React from "react";
import { ChangeHandler } from "react-hook-form";

type TextFieldProps = {
  disabled?: boolean;
  error?: string;
  label: string;
  type?: string;
  helper?: string;
  horizontal?: boolean;
  name: string;
  rows?: string | number;
  placeholder?: string;
  onChange?: ChangeHandler;
  required?: boolean;
};

export default React.forwardRef<HTMLTextAreaElement, TextFieldProps>(
  function TextAreaField(
    {
      disabled,
      error,
      helper,
      label,
      placeholder,
      name,
      horizontal,
      onChange,
      required,
    },
    ref
  ) {
    const orientation = clsx(horizontal ? "flex-row items-center" : "flex-col");

    return (
      <div className="relative flex flex-col flex-auto gap-y-1">
        <label className={`flex gap-2 ${orientation}`}>
          <span
            className={clsx(
              "flex-shrink-0",
              error ? "text-lns-error" : "text-lns-gray-text"
            )}
          >
            {label}
            {required && " *"}
          </span>

          <textarea
            className={clsx(
              "w-full rounded",
              error ? "border-lns-error" : "border-lns-gray-border"
            )}
            disabled={disabled}
            ref={ref}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
          />
        </label>

        {error && (
          <span className="absolute left-0 self-start -bottom-6 text-lns-error text-xs">
            {error}
          </span>
        )}

        {helper && (
          <span className="absolute right-0 self-end text-xs -bottom-6 text-lns-gray-text">
            {helper}
          </span>
        )}
      </div>
    );
  }
);
