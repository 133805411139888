import clsx from "clsx";
import React, { ReactNode } from "react";

type RadioGroupFieldProps = {
  error?: string;
  label?: string;
  children?: ReactNode[];
  horizontal?: boolean;
  required?: boolean;
};

export default React.forwardRef<HTMLDivElement, RadioGroupFieldProps>(
  function RadioGroupField(
    { error, label, horizontal, children, required },
    ref
  ) {
    const orientation = horizontal
      ? "flex-row gap-x-6 items-center h-10"
      : "flex-col";

    return (
      <div
        className="relative flex flex-col flex-auto gap-2"
        role="radiogroup"
        ref={ref}
      >
        {label && (
          <label className="flex flex-col mb-0.5">
            <span
              className={clsx(
                "flex-shrink-0",
                error ? "text-lns-error" : "text-lns-gray-text"
              )}
            >
              {label}
              {required && " *"}
            </span>
          </label>
        )}

        <div className={`flex gap-3 ${orientation}`}>{children}</div>
        {error && (
          <span className="absolute left-0 self-start -bottom-6 text-lns-error text-xs">
            {error}
          </span>
        )}
      </div>
    );
  }
);
