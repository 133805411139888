import clsx from "clsx";
import { addMonths } from "date-fns";
import { useMemo } from "react";
import { Search } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import useBreakpoints from "../../../hooks/useBreakpoints";
import { findEventsForDay, getWeeksInMonth } from "../../../utils/calendar";
import { leftPad } from "../../../utils/formatting";
import { useCalendar } from "../Calendar";
import "../Calendar.css";
import Controls from "../Controls";
import EventList from "../EventList";

export default function ViewModeMonth() {
  const {
    date,
    events,
    selectedEvent,
    minDate,
    maxDate,
    setDate,
    onEventClick,
    onShowMoreClick,
  } = useCalendar();
  const { t } = useTranslation();
  const [isMobile] = useBreakpoints();
  const weeks = useMemo(() => getWeeksInMonth(date), [date]);
  const weekDays = weeks[0];

  const prevMonthAvailable = minDate ? addMonths(date, -1) >= minDate : true;
  const nextMonthAvailable = maxDate ? addMonths(date, 1) <= maxDate : true;

  return (
    <>
      <Controls
        onClickPrev={() => setDate(addMonths(date, -1))}
        onClickNext={() => setDate(addMonths(date, 1))}
        canGoPrev={prevMonthAvailable}
        canGoNext={nextMonthAvailable}
      >
        {t("components.calendar.month_year", { date })}
      </Controls>

      <div className="table-container-border-rounded">
        <table className="w-full">
          <thead>
            <tr>
              {weekDays.map((weekDay, idx) => (
                <th
                  className="p-2 font-normal border border-lns-gray-border"
                  key={idx}
                >
                  <span>
                    {t(
                      isMobile
                        ? "components.calendar.day_of_week_short"
                        : "components.calendar.day_of_week",
                      { date: weekDay }
                    )}
                  </span>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {weeks.map((weekDays, idx) => (
              <tr key={idx}>
                {weekDays.map((day, idx) => {
                  const dayEvents = findEventsForDay(day, events);
                  const hasDayEvents = !!dayEvents.length;
                  const dayOfTheMonth = day.getMonth() === date.getMonth();

                  return (
                    <td
                      className="relative border border-lns-gray-border Cell"
                      style={{ height: 74, width: "calc(100% / 7)" }}
                      key={idx}
                    >
                      <div className="absolute inset-0">
                        <div
                          className={clsx(
                            "h-full flex flex-col bg-white p-0.5 lg:p-2 lg:gap-y-2",
                            {
                              "bg-lns-gray-disabled": !dayOfTheMonth,
                            }
                          )}
                        >
                          <span className="text-right">
                            {leftPad(day.getDate(), 2)}
                          </span>

                          <div className="flex items-center justify-center flex-1 overflow-auto lg:items-start">
                            {hasDayEvents && (
                              <button
                                onClick={() => onShowMoreClick?.(day)}
                                data-testid="MobileViewMore"
                                type="button"
                                className="flex p-3 rounded text-lns-white bg-lns-primary lg:hidden"
                              >
                                <Search size={16} className="flex-1" />
                              </button>
                            )}

                            <div className="flex-1 hidden lg:block">
                              <EventList
                                events={dayEvents}
                                selectedEvent={selectedEvent}
                                onEventClick={onEventClick}
                                showMoreButton={3}
                                onShowMoreClick={() => onShowMoreClick?.(day)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
