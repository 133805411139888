import clsx from "clsx";
import capitalize from "lodash/capitalize";
import _orderBy from "lodash/orderBy";
import { Fragment, ReactNode, useMemo } from "react";
import {
  ArrowDown,
  ArrowDownUp,
  ArrowUp,
  Calendar,
  Clock,
} from "react-bootstrap-icons";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSetState } from "react-use";
import { useApp } from "../../contexts/AppContext";
import { Appointment } from "../../models/appointment";
import { formatISO } from "../../utils/generic";
import Badge from "../Badge/Badge";
import Button from "../Button/Button";
import DropDownField from "../Inputs/DropDownField";
import Panel from "../Panel/Panel";

type AppointmentsTableProps = {
  appointments?: Appointment[];
};

type TableConfiguration = {
  id: string;
  label: string;
  sortable?: boolean;
  sortValue?: (row: Appointment) => string | Date | number | boolean;
  className?: string;
  hidden?: boolean;
  render?: (row: Appointment) => ReactNode;
};

function getConfiguration(isMisa?: boolean): TableConfiguration[] {
  return [
    {
      id: "reference",
      label: "components.appointment_table.reference",
    },
    {
      id: "name",
      label: "components.appointment_table.name",
      sortable: true,
      className: "lg:col-span-2",
      hidden: !isMisa,
      render: ({ partner_id }) =>
        `${capitalize(partner_id?.firstname)} ${capitalize(
          partner_id?.lastname
        )}`,
    },
    {
      id: "appointment_type",
      label: "components.appointment_table.appointment_type",
      sortable: true,
      className: "lg:col-span-2",
      hidden: isMisa,
      render: ({ appointment_type }) => (
        <Trans i18nKey={`models.appointment.analyzes.${appointment_type}`} />
      ),
    },
    {
      id: "date",
      label: "components.appointment_table.date",
      sortable: true,
      sortValue: ({ start_datetime }) => new Date(start_datetime),
      render: ({ start_datetime }) => (
        <div className="flex flex-row items-center gap-2">
          <div>
            <Calendar />
          </div>
          <span className="truncate">
            {formatISO(start_datetime, "d-MM-yyyy")}
          </span>
        </div>
      ),
    },
    {
      id: "time",
      label: "components.appointment_table.time",
      sortable: false,
      render: ({ start_datetime }) => (
        <div className="flex flex-row items-center gap-2">
          <div>
            <Clock />
          </div>
          <span className="truncate">{formatISO(start_datetime, "HH:mm")}</span>
        </div>
      ),
    },
    {
      id: "status",
      label: "components.appointment_table.status",
      sortable: true,
      sortValue: ({ state_full }) => state_full,
      className: "lg:col-span-2",
      render: ({ state_full }) => (
        <Badge state={state_full}>
          <Trans i18nKey={`models.appointment.state.${state_full}`} />
        </Badge>
      ),
    },
    {
      id: "reports",
      label: "components.appointment_table.documents",
      className: "lg:col-span-2",
      render: ({
        appointment_type,
        documents = [],
        document_count = 0,
        state_full,
      }) => {
        let count = documents.length || document_count;
        // Remove the glims report from the document counter for environment appoinment and no misa users
        // TODO- better to move this recalculation to the backend
        if (
          !isMisa &&
          appointment_type === "environmental" &&
          (state_full === "waiting_misa" ||
            state_full === "due" ||
            state_full === "done")
        ) {
          count = count - 1;
        }
        return (
          <strong>
            {count > 0 ? (
              <Trans
                i18nKey="components.appointment_table.documents_cell"
                values={{ count }}
              />
            ) : (
              "-"
            )}
          </strong>
        );
      },
    },
  ];
}

export default function AppointmentsTable({
  appointments = [],
}: AppointmentsTableProps) {
  const { isMisa } = useApp();
  const { t } = useTranslation();

  const configuration = useMemo(
    () => getConfiguration(isMisa).filter((c) => !c.hidden),
    [isMisa]
  );

  const [orderBy, setOrderBy] = useSetState({
    sortBy: "",
    ascDesc: "",
  });

  function handleSortChange(column: string) {
    setOrderBy({
      sortBy: column,
      ascDesc:
        orderBy.sortBy !== column
          ? "asc"
          : orderBy.ascDesc === "asc"
            ? "desc"
            : "asc",
    });
  }

  const currentSortColumn = configuration.find(
    (config) => config.id === orderBy.sortBy
  );

  const sortedAppointments = useMemo(() => {
    if (currentSortColumn) {
      return _orderBy(
        appointments,
        [
          currentSortColumn?.sortValue
            ? (data) => currentSortColumn?.sortValue?.(data)
            : orderBy.sortBy,
        ],
        [orderBy.ascDesc === "asc" ? "asc" : "desc"]
      );
    }
    return appointments;
  }, [appointments, currentSortColumn, orderBy.ascDesc, orderBy.sortBy]);

  return (
    <div className="mt-4">
      <div className="flex flex-col gap-4">
        <div className="flex flex-row items-center gap-1 lg:hidden">
          <DropDownField
            label={t("components.appointment_table.sort_by")}
            name=""
            value={
              currentSortColumn
                ? {
                    ...currentSortColumn,
                    value: currentSortColumn.id,
                  }
                : undefined
            }
            onChange={(value) => handleSortChange(value.id as string)}
            options={[
              { value: "", label: "-" },
              ...configuration
                .filter((config) => config.sortable)
                .map((config) => ({
                  ...config,
                  label: t(config.label),
                  value: config.id,
                })),
            ]}
          />
          {currentSortColumn && (
            <div
              className="flex items-center justify-center flex-none p-3 mt-6"
              onClick={() =>
                currentSortColumn.sortable &&
                handleSortChange(currentSortColumn.id)
              }
            >
              {orderBy.sortBy === currentSortColumn.id ? (
                orderBy.ascDesc === "asc" ? (
                  <ArrowUp />
                ) : (
                  <ArrowDown />
                )
              ) : (
                <ArrowDownUp />
              )}
            </div>
          )}
        </div>

        <div className="flex-col justify-between hidden gap-4 px-6 py-3 border-b gap-x-0 border-lns-gray-background lg:items-center lg:grid lg:grid-cols-10">
          {configuration.map((config) => (
            <div
              key={config.id}
              onClick={() => config.sortable && handleSortChange(config.id)}
              className={clsx(
                "flex flex-row items-center gap-2",
                config.sortable && "cursor-pointer",
                config.className
              )}
            >
              <div className="truncate text-lns-gray-text">
                {t(config.label)}
              </div>
              {config.sortable &&
                (orderBy.sortBy === config.id ? (
                  orderBy.ascDesc === "asc" ? (
                    <ArrowUp />
                  ) : (
                    <ArrowDown />
                  )
                ) : (
                  <ArrowDownUp />
                ))}
            </div>
          ))}
          <div className="lg:col-span-1" />
        </div>

        {sortedAppointments.map((app) => (
          <Panel key={app.reference} className="lg:py-4">
            <div className="grid items-center grid-cols-2 gap-4 gap-x-0 lg:grid-cols-10">
              {configuration.map((config) => (
                <Fragment key={config.id}>
                  <span className="lg:hidden">{t(config.label)}:</span>
                  <span className={clsx("truncate", config.className)}>
                    {config.render?.(app) ||
                      app[config.id as keyof Appointment]?.toString()}
                  </span>
                </Fragment>
              ))}
              <Button
                className="w-full col-span-2 lg:text-sm lg:col-span-1"
                to={`/analyses/${app.appointment_type}/${app.id}`}
                as={Link}
              >
                {t("components.buttons.more_details")}
              </Button>
            </div>
          </Panel>
        ))}
      </div>
    </div>
  );
}
