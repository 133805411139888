import { Dialog } from "@headlessui/react";
import { ReactNode, useRef } from "react";
import { X as XIcon } from "react-bootstrap-icons";
import { useMountedState } from "react-use";

export type ModalProps = {
  open: boolean;
  header?: React.ReactNode;
  size?: string;
  children?: ReactNode;
  onCloseButtonClick?: () => void;
};

export default function Modal({
  open,
  header,
  children,
  onCloseButtonClick,
  size = "lg:max-w-xl",
}: ModalProps) {
  const defaultFocusRef = useRef(null);

  //https://github.com/tailwindlabs/headlessui/issues/672
  const isMounted = useMountedState();

  return (
    <>
      {isMounted() && (
        <Dialog
          open={open}
          onClose={() => {}}
          className="fixed inset-0 z-10 overflow-y-auto"
          initialFocus={defaultFocusRef}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-lns-black opacity-40" />

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div
              className={`inline-flex flex-col w-full p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-lns-gray-disabled shadow-xl ${size} rounded-2xl`}
            >
              {header && (
                <Dialog.Title
                  as="h3"
                  className="my-4 text-xl font-medium text-center md:text-2xl"
                >
                  {header}
                </Dialog.Title>
              )}

              {onCloseButtonClick && (
                <div className="absolute top-1 right-1">
                  <button
                    data-testid="close"
                    type="button"
                    className="inline-flex justify-center px-2 py-1 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => onCloseButtonClick()}
                  >
                    <XIcon size={30} />
                  </button>
                </div>
              )}

              {children}

              <button
                className="bottom-0 opacity-0 absolute-0"
                ref={defaultFocusRef}
              ></button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
}
