import { Listbox, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { CaretDownFill, Check } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { SUPPORTED_LANGUAGES } from "../../config";
import { useApp } from "../../contexts/AppContext";
import { updateProfileLanguage } from "../../services/ProfileService";

interface Lang {
  name: string;
}

function dropDownArray(items: any[]) {
  return items.map((i) => ({ name: i }));
}

export default function LanguageDropdown() {
  const { i18n } = useTranslation();
  const { showError } = useApp();

  const langs = dropDownArray(SUPPORTED_LANGUAGES);

  const selected: Lang =
    langs.find((l) => l.name === i18n.language) || langs[0];

  async function handleChange(lang: Lang) {
    i18n.changeLanguage(lang.name);

    try {
      await updateProfileLanguage({ lang: lang.name });
    } catch (exception: any) {
      showError(exception);
    }
  }

  return (
    <Listbox value={selected} onChange={handleChange}>
      <div className="relative px-2.5 pr-0">
        <Listbox.Button className="flex flex-row items-center justify-center w-full gap-1 py-2 focus:outline-none">
          <span className="flex-1 uppercase">{selected.name}</span>
          <CaretDownFill aria-hidden="true" />
        </Listbox.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Listbox.Options className="absolute right-0 w-20 mt-2 origin-top-right rounded-md shadow-lg bg-lns-white focus:outline-none">
            {langs.map((lang, idx) => (
              <div className="px-1 py-1" key={idx}>
                <Listbox.Option
                  className={({ active }) =>
                    `${active ? "bg-lns-gray-background" : null}
                  text-lns-black group flex rounded-md items-center w-full px-2 py-2 relative cursor-pointer`
                  }
                  value={lang}
                >
                  {({ selected }) => (
                    <>
                      <span className="block ml-5 uppercase truncate">
                        {lang.name}
                      </span>

                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-1">
                          <Check className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              </div>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
