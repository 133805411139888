import { useParams } from "react-router-dom";
import Wizard from "../components/Wizard/Wizard";
import { getBookingWizardConfigByType } from "../utils/generic";

export type BookingParams = {
  appointment_type: string;
};

export default function BookingPage() {
  const { appointment_type } = useParams<BookingParams>();
  const configs = getBookingWizardConfigByType(appointment_type);
  return <Wizard configs={configs} />;
}
