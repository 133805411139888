import { DEBUG } from "../config";
import { Profile } from "../models/profile";
import { loadUserProfile } from "./AuthService";

if (DEBUG) {
  require("./DevEnvMockServices");
}

export async function getInitializer(): Promise<Profile> {
  return loadUserProfile();
}
