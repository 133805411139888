import { Timeslot } from "../models/timeslot";

export function buildDate(day: number, hours: number, mins: number) {
  const date = new Date();
  date.setDate(day);
  date.setHours(hours, mins);
  return date;
}

const datetimes = [
  buildDate(11, 8, 0),
  buildDate(11, 8, 30),
  buildDate(11, 8, 15),
  buildDate(11, 8, 45),
  buildDate(11, 9, 0),
  buildDate(11, 9, 15),
  buildDate(11, 9, 30),
  buildDate(11, 9, 45),
  buildDate(11, 10, 0),
  buildDate(11, 10, 15),
  buildDate(11, 10, 30),
  buildDate(11, 10, 45),

  buildDate(15, 8, 15),
  buildDate(15, 8, 30),
  buildDate(15, 8, 45),

  buildDate(16, 8, 0),
  buildDate(16, 8, 15),
  buildDate(16, 8, 30),
  buildDate(16, 8, 45),

  buildDate(19, 8, 15),
  buildDate(19, 8, 30),
  buildDate(19, 8, 45),

  buildDate(23, 8, 0),
  buildDate(23, 8, 15),
];

const timeslots: Timeslot[] = datetimes.map((dt, idx) => ({
  id: idx.toString(),
  state: "open",
  start_datetime: dt,
  end_datetime: dt,
}));

export default timeslots;
