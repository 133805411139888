import { WizardConfigs } from "./components/Wizard/Wizard";
import ConfirmationAllergologyStep from "./pages/BookingSteps/Allergological/ConfirmationAllergologyStep";
import GetInfoAllergologicalStep from "./pages/BookingSteps/Allergological/GetInfoAllergologicalStep";
import WelcomeAllergologyStep from "./pages/BookingSteps/Allergological/WelcomeAllergologyStep";
import ConfirmationBiologicalStep from "./pages/BookingSteps/Biological/ConfirmationBiologicalStep";
import GetInfoBiologicalStep from "./pages/BookingSteps/Biological/GetInfoBiologicalStep";
import WelcomeBiologicalStep from "./pages/BookingSteps/Biological/WelcomeBiologicalStep";
import CalendarStep from "./pages/BookingSteps/CalendarStep";
import ConfirmationEnvironmentalStep from "./pages/BookingSteps/Environmental/ConfirmationEnvironmentalStep";
import GetInfoEnvironmentalStep from "./pages/BookingSteps/Environmental/GetInfoEnvironmentalStep";
import WelcomeEnvironmentalStep from "./pages/BookingSteps/Environmental/WelcomeEnvironmentalStep";
import ConfirmationToxicologicalStep from "./pages/BookingSteps/Toxicological/ConfirmationToxicologicalStep";
import WelcomeToxicologicalStep from "./pages/BookingSteps/Toxicological/WelcomeToxicologicalStep";

const BookingWizardConfigs: { [key: string]: WizardConfigs } = {
  TOXICOLOGY: {
    root: "/booking/toxicology",
    steps: [
      {
        path: "welcome",
        Component: WelcomeToxicologicalStep,
      },
      {
        path: "booking",
        Component: CalendarStep,
      },
      {
        path: "confirmation",
        Component: ConfirmationToxicologicalStep,
      },
    ],
    initialValues: {
      appointment_type: "toxicology",
    },
  },

  BIOLOGICAL: {
    root: "/booking/biological",
    steps: [
      {
        path: "welcome",
        Component: WelcomeBiologicalStep,
      },
      {
        path: "get-info",
        Component: GetInfoBiologicalStep,
      },
      {
        path: "booking",
        Component: CalendarStep,
      },
      {
        path: "confirmation",
        Component: ConfirmationBiologicalStep,
      },
    ],
    initialValues: {
      appointment_type: "biological",
    },
  },

  ENVIRONMENTAL: {
    root: "/booking/environmental",
    steps: [
      {
        path: "welcome",
        Component: WelcomeEnvironmentalStep,
      },
      {
        path: "get-info",
        Component: GetInfoEnvironmentalStep,
      },
      {
        path: "booking",
        Component: CalendarStep,
      },
      {
        path: "confirmation",
        Component: ConfirmationEnvironmentalStep,
      },
    ],
    initialValues: {
      appointment_type: "environmental",
    },
  },

  ALLERGOLOGY: {
    root: "/booking/allergology",
    steps: [
      {
        path: "welcome",
        Component: WelcomeAllergologyStep,
      },
      {
        path: "get-info",
        Component: GetInfoAllergologicalStep,
      },
      {
        path: "booking",
        Component: CalendarStep,
      },
      {
        path: "confirmation",
        Component: ConfirmationAllergologyStep,
      },
    ],
    initialValues: {
      appointment_type: "allergology",
    },
  },
};

export default BookingWizardConfigs;
