import isEmpty from "lodash/isEmpty";
import { Trans, useTranslation } from "react-i18next";
import Container from "../../components/Layout/Container";
import SubTitle from "../../components/Typography/SubTitle";
import { AppointmentDetails } from "../../models/appointment";
import { AllergologicalSurvey } from "../../models/survey";
import SurveyAllergological from "../BookingSteps/Allergological/SurveyAllergological";
import SurveyBiological from "../BookingSteps/Biological/SurveyBiological";
import SurveyCompanyEnvironmental from "../BookingSteps/Environmental/SurveyCompanyEnvironmental";
import SurveyPrivateEnvironmental from "../BookingSteps/Environmental/SurveyPrivateEnvironmental";

type SummaryTabProps = {
  appointment: AppointmentDetails;
};

export default function SummaryTab({ appointment }: SummaryTabProps) {
  const { appointment_type } = appointment;
  const hasAnswers = !isEmpty(appointment?.survey);
  const { t } = useTranslation();

  return (
    <>
      {appointment_type === "allergology" && (
        <>
          <div className="bg-lns-gray-background">
            <Container padded className="py-8 space-y-8 lg:py-12">
              <SubTitle>
                {t("pages.appointment_details.summary.title", {
                  appointment_type,
                }).toUpperCase()}
              </SubTitle>
              <Trans
                i18nKey="pages.booking.allergology.steps.welcome.instructions"
                components={{
                  ul: <ul className="list-disc list-inside space-y-6" />,
                }}
              />
            </Container>
          </div>

          {hasAnswers && (
            <Container padded className="mt-8 space-y-8">
              <SubTitle>
                {t(
                  "pages.appointment_details.summary.additional_info"
                ).toUpperCase()}
              </SubTitle>
              <SurveyAllergological
                answers={appointment}
                isChildConsult={
                  (appointment?.survey as AllergologicalSurvey)
                    ?.child_consult === "yes"
                }
              />
            </Container>
          )}
        </>
      )}

      {appointment_type === "toxicology" && (
        <div className="bg-lns-gray-background">
          <Container padded className="py-8 space-y-8 lg:py-12">
            <SubTitle>
              {t("pages.appointment_details.summary.title", {
                appointment_type,
              }).toUpperCase()}
            </SubTitle>
            <Trans
              i18nKey="pages.booking.toxicological.steps.welcome.instructions"
              components={{
                ul: <ul className="list-disc list-inside space-y-6" />,
              }}
            />
          </Container>
        </div>
      )}

      {appointment_type === "biological" && (
        <>
          <div className="bg-lns-gray-background">
            <Container padded className="py-8 space-y-8 lg:py-12">
              <SubTitle>
                {t("pages.appointment_details.summary.title", {
                  appointment_type,
                }).toUpperCase()}
              </SubTitle>
              <Trans
                i18nKey="pages.booking.biological.steps.welcome.instructions"
                components={{
                  ul: <ul className="list-disc list-inside space-y-6" />,
                }}
              />
            </Container>
          </div>

          {hasAnswers && (
            <Container padded className="mt-8 space-y-8">
              <SubTitle>
                {t(
                  "pages.appointment_details.summary.additional_info"
                ).toUpperCase()}
              </SubTitle>
              <SurveyBiological answers={appointment} />
            </Container>
          )}
        </>
      )}

      {appointment_type === "environmental" && (
        <>
          <div className="bg-lns-gray-background">
            <Container padded className="py-8 space-y-8 lg:py-12">
              <SubTitle>
                {t("pages.appointment_details.summary.title", {
                  appointment_type,
                }).toUpperCase()}
              </SubTitle>
              <Trans
                i18nKey={
                  appointment.partner_type === "enterprise"
                    ? "pages.booking.environmental.steps.welcome.company.instructions"
                    : "pages.booking.environmental.steps.welcome.private.instructions"
                }
                components={{
                  ul: <ul className="list-disc list-inside space-y-6" />,
                }}
              />
            </Container>
          </div>

          {hasAnswers && (
            <Container padded className="mt-8 space-y-8">
              <SubTitle>
                {t(
                  "pages.appointment_details.summary.additional_info"
                ).toUpperCase()}
              </SubTitle>

              {appointment.partner_type === "patient" && (
                <SurveyPrivateEnvironmental answers={appointment} />
              )}

              {appointment.partner_type === "enterprise" && (
                <SurveyCompanyEnvironmental answers={appointment} />
              )}
            </Container>
          )}
        </>
      )}
    </>
  );
}
