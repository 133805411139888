import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { CaretDownFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { DEBUG } from "../../config";
import { useApp } from "../../contexts/AppContext";
import {
  doAccountManagement,
  doLogout,
  Roles,
} from "../../services/AuthService";

export default function ProfileDropdown() {
  const { isMisa, profile } = useApp();
  const { t } = useTranslation();

  // Switch role for demo purpose
  function loginAs(role: string) {
    sessionStorage.setItem("demo-role", role);
    window.location.assign("/");
  }

  return (
    <Menu as="div" className="relative px-2.5">
      <div>
        <Menu.Button className="flex flex-row items-center justify-center w-full gap-1 py-2 focus:outline-none">
          <span className="block truncate w-28 sm:w-full">
            {profile?.firstName}{" "}
            <span className="uppercase">{profile?.lastName}</span>
          </span>
          <CaretDownFill aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right divide-y rounded-md shadow-lg divide-lns-gray-border bg-lns-white focus:outline-none">
          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-lns-gray-background" : null
                  } text-lns-black text-left rounded-md w-full px-2 py-2`}
                  onClick={() => doAccountManagement()}
                >
                  {t("components.buttons.profile_settings")}
                </button>
              )}
            </Menu.Item>
            {DEBUG && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-lns-gray-background" : null
                    } text-lns-black text-left rounded-md w-full px-2 py-2`}
                    onClick={() =>
                      loginAs(!isMisa ? Roles.MISA : Roles.PATIENT)
                    }
                  >
                    {`Login as ${!isMisa ? "MISA" : "Patient"} user (demo)`}
                  </button>
                )}
              </Menu.Item>
            )}
          </div>
          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-lns-gray-background" : null
                  } text-lns-black text-left rounded-md w-full px-2 py-2`}
                  onClick={() => doLogout()}
                >
                  {t("components.buttons.log_out")}
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
