import { format as formatDate, isDate } from "date-fns";
import { de, enUS, fr, pt } from "date-fns/locale";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { DEBUG, FALLBACK_LANGUAGE, SUPPORTED_LANGUAGES } from "./config";

const locales = { de, en: enUS, fr, pt };

type DateLocales = keyof typeof locales;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: FALLBACK_LANGUAGE,
    supportedLngs: SUPPORTED_LANGUAGES,
    debug: DEBUG,
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (isDate(value)) {
          const dateLng =
            lng && locales.hasOwnProperty(lng) ? lng : FALLBACK_LANGUAGE;
          const dateFormat = format || "";
          const locale = locales[dateLng as DateLocales];
          return formatDate(value, dateFormat, { locale });
        }
        return value;
      },
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "ul", "li"],
    },
  });

export default i18n;
