const endpoints = {
  // Invoices
  getToxicologyInvoices: () => "/portal/toxicology/invoices",
  getToxicologyInvoicePDF: (invoiceId: string) =>
    `/portal/toxicology/invoices/${invoiceId}/pdf`,

  // Appointment
  getAppointmentDetails: (
    appointmentId: string,
    appointment_type: string,
    misa: boolean
  ) =>
    `/${
      misa ? "misa" : "portal"
    }/${appointment_type}/appointments/${appointmentId}`,

  downloadAppointmentDocument: () => `/download_appointment_document`,

  downloadAppointmentInvoice: (invoiceId: number) =>
    `/portal/toxicology/invoices/${invoiceId}/pdf`,

  downloadMISADocument: () => `/misa/download_misa_document`,

  getAppointments: (misa: boolean = false) =>
    `${misa ? "/misa" : "/portal"}/appointments`,

  createAppointment: (appointment_type: string) =>
    `/portal/${appointment_type}/appointments`,

  deleteAppointment: (
    appointmentId: number | string,
    appointment_type: string
  ) => `/portal/${appointment_type}/appointments/${appointmentId}`,
  // Misa workflow

  approveMisaEnvironmentalAppointment: (id: number | string) =>
    `/misa/environmental/appointments/${id}/approval`,

  validateMisaEnvironmentalAppointment: (id: number | string) =>
    `/misa/environmental/appointments/${id}/validation`,

  // Timeslot
  getTimeslots: (appointment_type: string) =>
    `/portal/${appointment_type}/timeslots`,

  // Profile
  getProfile: () => `/portal/profile`,
  updateProfileLanguage: () => `/portal/profile/lang`,
};

export default endpoints;
