type LoadingProps = {
  message?: string;
};

export default function Loading({ message = "Loading..." }: LoadingProps) {
  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center flex-auto bg-lns-white">
      <span className="animation animate-pulse">{message}</span>
    </div>
  );
}
