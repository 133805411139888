import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../../../components/Button/Button";
import Container from "../../../components/Layout/Container";
import PageTitle from "../../../components/Typography/PageTitle";
import { useWizard } from "../../../components/Wizard/Wizard";
import SurveyBiological from "./SurveyBiological";

export default function GetInfoBiologicalStep() {
  const { t } = useTranslation();
  const { goToNextStep, prevStep } = useWizard();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <form onSubmit={handleSubmit(goToNextStep)} noValidate>
      <Container padded>
        <PageTitle>
          {t("pages.booking.biological.steps.survey.title")}
        </PageTitle>

        <p className="mb-8">
          {t("pages.booking.biological.steps.survey.instructions.0")}
        </p>

        <SurveyBiological errors={errors} register={register} />

        <div className="flex my-8 gap-x-6">
          <Button variant="outline-primary" as={Link} to={prevStep?.path}>
            {t("components.buttons.cancel")}
          </Button>

          <Button variant="primary" type="submit">
            {t("components.buttons.next")}
          </Button>
        </div>
      </Container>
    </form>
  );
}
