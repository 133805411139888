import { ReactNode } from "react";
import { Redirect, useParams } from "react-router-dom";
import { isAppointmentTypeEnabled } from "../../utils/appointment";

type AppointmentRouteProps = {
  children: ReactNode;
};

type AppointmentRouteParams = {
  appointment_type: string;
};

export default function AppointmentRoute({ children }: AppointmentRouteProps) {
  let { appointment_type } = useParams<AppointmentRouteParams>();
  if (!isAppointmentTypeEnabled(appointment_type)) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }
  return <>{children}</>;
}
