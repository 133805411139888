import clsx from "clsx";
import React from "react";
import { ChangeHandler } from "react-hook-form";

type TextFieldProps = {
  error?: string;
  label?: string;
  helper?: string;
  horizontal?: boolean;
  name: string;
  placeholder?: string;
  value?: string;
  onChange?: ChangeHandler | ((event: any) => void);
  // TODO - Improve react-hook-form integration and avoid duplicating required props
  required?: boolean;
  // display the component as a text
  answer?: string;
  autocomplete?: string;
  type?: "text" | "date" | "email";
};

export default React.forwardRef<HTMLInputElement, TextFieldProps>(
  function TextField(
    {
      error,
      helper,
      required,
      autocomplete,
      label,
      name,
      horizontal,
      placeholder,
      onChange,
      answer,
      type = "text",
      value,
    },
    ref
  ) {
    const hasAnswer = answer !== undefined;
    const orientation = clsx(horizontal ? "flex-row items-center" : "flex-col");

    return (
      <div className="relative flex flex-col flex-auto gap-y-1">
        <label className={`flex gap-2 ${orientation}`}>
          {label && (
            <span
              className={clsx(
                "flex-shrink-0",
                error ? "text-lns-error" : "text-lns-gray-text"
              )}
            >
              {label}
              {required && " *"}
            </span>
          )}

          {!hasAnswer && (
            <input
              type={type}
              className={clsx(
                "w-full rounded",
                error ? "border-lns-error" : "border-lns-gray-border"
              )}
              ref={ref}
              autoComplete={autocomplete}
              name={name}
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              required={required}
            />
          )}

          {hasAnswer && (
            <div className="w-full rounded border-lns-gray-border">
              {answer}
            </div>
          )}
        </label>

        {error && (
          <span className="absolute left-0 self-start -bottom-6 text-lns-error text-xs">
            {error}
          </span>
        )}

        {!hasAnswer && helper && !error && (
          <span className="absolute right-0 self-end text-xs -bottom-6 text-lns-gray-text">
            {helper}
          </span>
        )}
      </div>
    );
  }
);
