import { useTranslation } from "react-i18next";
import packageJson from "../../../package.json";

export default function Footer() {
  const { i18n, t } = useTranslation();

  return (
    <footer className="mt-6 text-center lg:text-left text-lns-gray-text">
      <hr className="border-lns-gray-border" />

      <div className="flex flex-col py-4 gap-y-2 lg:flex-row lg:justify-between">
        <div className="flex-1">
          <p>
            {t("footer.copyright", { year: new Date().getFullYear() })} -{" "}
            {packageJson.version}
          </p>
        </div>

        <div className="flex justify-center gap-x-3 lg:justify-start">
          <a
            href={
              i18n.language === "fr" ? "https://lns.lu" : "https://lns.lu/en/"
            }
            className="underline"
          >
            {t("components.buttons.lns_website")}
          </a>

          <a
            href={
              i18n.language === "fr"
                ? "https://lns.lu/mentions-legales/"
                : "https://lns.lu/en/legal-notice/"
            }
            className="underline"
          >
            {t("components.buttons.legal_notice")}
          </a>

          <a
            href={
              i18n.language === "fr"
                ? "https://lns.lu/contact/"
                : "https://lns.lu/en/contact-en/"
            }
            className="underline"
            target="_blank"
            rel="noreferrer"
          >
            {t("components.buttons.contact")}
          </a>
        </div>
      </div>
    </footer>
  );
}
