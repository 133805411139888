import clsx from "clsx";
import React from "react";
import { ChangeHandler } from "react-hook-form";

type RadioFieldProps = {
  label: string;
  name: string;
  value: string;
  onChange?: ChangeHandler;
};

export default React.forwardRef<HTMLInputElement, RadioFieldProps>(
  function RadioField({ label, name, value, onChange }, ref) {
    return (
      <label className={clsx("flex items-center space-x-3 cursor-pointer")}>
        <input
          type="radio"
          className="rounded-full border-lns-gray-border"
          ref={ref}
          name={name}
          onChange={onChange}
          value={value}
        />
        <span className="inline-flex">{label}</span>
      </label>
    );
  }
);
